<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner">
        <h1>新城控股董事长猥亵女童</h1>
        <p><span>报告于2019-08-13 19:33:57自动生成</span></p>
      </div>
      <div class="btn_tab" id="network-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a class="selected cur-p" @click="switchTab(0, true)">事件概述</a>
              <a class="cur-p" @click="switchTab(1, true)">事件起源分析</a>
              <a class="cur-p" @click="switchTab(2, true)">事件走势分析</a>
              <a class="cur-p" @click="switchTab(3, true)">信息传播途经分析</a>
              <a class="cur-p" @click="switchTab(4, true)">传播内容分析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 事件概述 -->
        <a name="sjgs" id="sjgs"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件概述</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p :class="row.text ? 'paragraph' : 'paragraph lh-30'" v-for="(row, index) in data.web_summary" :key="index">
                <strong v-if="row.text">{{ row.text }}：</strong>
                <span v-if="!isArray(row.summary)">{{ row.summary }}</span>
                <span v-for="(tag,index) in row.summary" :key="index" class="text-tag" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <!-- 事件起源分析 -->
        <a name="qyfx" id="qyfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件起源分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p :class="index == 0 ? 'paragraph lh-30' : 'paragraph'" v-for="(row, index) in data.web_source" :key="index">
                {{ row }}
              </p>
            </div>
          </el-card>
        </div>
        <!-- 事件走势分析 -->
        <a name="zsfx" id="zsfx"></a>
        <div class="warpper pt-20 hide">
          <el-card class="box-card">
            <h3 class="mod-title">事件走势分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div v-for="(row, index) in data['web_trend']" :key="index">
                <div v-if="row.hasOwnProperty('text')">
                  <p class="paragraph lh-30">{{ row.text }}</p>
                  <p class="paragraph" v-for="(item, index) in row.item" :key="index">{{ item }}</p>
                </div>
                <div v-else>
                  <p class="paragraph">
                    <strong>{{ row.key }}：</strong>
                    <span v-for="(tag, index) in row.tags" :key="index" class="text-tag">{{ tag }}</span>
                  </p>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">网媒信息量走势</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['web_number_trend'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="web_number_trend" v-show="data['web_number_trend'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">媒体信息来源类型分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="web_type"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">媒体事件走势</h3>
            <div class="pad-lr-20" v-if="!data['web_event_trend'].isIncubation">
              <div class="echarts_empty" v-if="data['web_event_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <!-- 时间轴 -->
              <div class="demo" v-else>
                <div class="main-timeline">
                  <div class="timeline" v-for="(row, index) in data['web_event_trend'].data" :key="index">
                    <div class="timeline-content">
                      <div class="circle">
                        <p class="title mar-t-10"><a :href="row.source_url" target="_blank" :title="row.title">{{ row.title }}</a></p>
                        <p class="name"><a>{{ row.media }}</a></p>
                      </div>
                      <div class="content">
                        <span class="year">{{ row.date }}</span>
                        <div class="icon"><span></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-lr-20" v-else>
              <div class="w-100 h-400" id="media-event-trend"></div>
              <div class="demo2">
                <div class="main-timeline2" v-for="(row, index) in data['web_event_trend'].data.timeline" :key="index">
                  <div class="item" v-if="row.type === 'point'">
                    <div class="main-title">
                      <div class="point">
                        <p v-for="(point, index) in row.points" :key="index">
                          <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                          <span>{{ point }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="circle_title">
                            <h4>
                              <a :href="row.article.source_url" target="_blank">{{ row.article.title }}</a>
                            </h4>
                            <dl>
                              <dd v-for="(media, index) in row.article.medias" :key="index">
                                <a>{{ media }}</a><span class="bd">|</span>
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div class="content">
                          <span class="year">{{ row.date }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item item_title" v-else>
                    <div class="main-title">
                      <h3>{{ row.state }}</h3>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content bd_n">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(article, index) in row.articles" :key="index">
                                <span class="num bg-555" v-if="index == 0">1</span>
                                <span class="num bg-689" v-else-if="index == 1">2</span>
                                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                <a class="mod-list-title2">{{ article.title }}</a>
                                <a class="site"><span>|</span>{{ article.media }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 信息传播途经分析 -->
        <a name="tjfx" id="tjfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">信息传播途经分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30" v-for="(row, index) in data['web_event_spread_path']" :key="index">
                <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                <span v-if="!isArray(row.text)">{{ row.text }}</span>
                <span v-for="(tag, index) in row.text" :key="index" class="text-tag" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card zyfbqd">
            <h3 class="mod-title">主要发布渠道</h3>
            <div class="pad-lr-20">
              <div class="mod-list">
                <ul>
                  <li>

                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title2">媒体名称</span>
                      <span class="bt">标题一</span>
                      <span class="bt">标题二</span>
                      <span class="bt">标题三</span>
                      <span class="xxzs">信息总数</span>
                      <span class="tag">趋势简图</span>
                    </h3>

                  </li>
                  <li v-for="(row, index) in data['web_main_platform']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :title="row.media">{{ row.media }}</a>
                    <a class="bt" v-for="(article, index) in row.article" :href="article.source_url" target="_blank" :key="index" :title="article.title">{{ article.title }}</a>
                    <span class="xxzs">{{ row.total }}条</span>
                    <div class="tag mini-trend" :miniid="index"></div>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件关键媒体走势</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['web_key_media_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="h-450" id="web_key_media_trend" v-show="data['web_key_media_trend'].data.length >= 1"></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件传播路径</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty hide">
                <div class="img"></div>
              </div>
              <div class="h-450" style="height: 450px;width: 600px;" id="web_spread_path"></div>
            </div>
          </el-card>
        </div>
        <!-- 传播内容分析 -->
        <a name="nrfx" id="nrfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">传播内容分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">{{ data['web_spread_content_analyse'] }}</p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card sjcbnr echarts-box">
                <h3 class="mod-title">事件传播内容</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="w-100 flex">
                    <div class="mod-list" v-show="data['web_spread_content'].length >= 1">
                      <ul>
                        <li v-for="(row, index) in data['web_spread_content']" :key="index" v-if="index <= 9">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title2" :title="row.keyword">{{ row.keyword }}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="w-100 h-290" id="web_spread_content" v-show="data['web_spread_content'].length >= 1"></div>
                    <div class="echarts_empty" v-show="data['web_spread_content'].length < 1">
                      <div class="img"></div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card h-355 ov-v">
                <h3 class="mod-title">事件传播情绪</h3>
                <div class="pad-lr-20 pos-r">
                  <div class="echarts_empty" v-show="data['web_spread_emotion'].length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-290" id="web_spread_emotion" v-show="data['web_spread_emotion'].length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件传播内容走势</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['web_spread_content_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="h-450" id="web_spread_content_trend" v-show="data['web_spread_content_trend'].data.length >= 1"></div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
//require("@/echarts2/echarts-all")
import { findPointImg, globalCreateChart, globalGetEcharts } from '@/utils/helpers.js'
const d3 = require("d3");
export default {
  name: 'analysis-propagation-network',
  data() {
    return {
      data: {
        web_number_trend: {
          keys: [
            '2019-07-01',
            '2019-07-02',
            '2019-07-03',
            '2019-07-04',
            '2019-07-05',
            '2019-07-06',
            '2019-07-07',
            '2019-07-08',
            '2019-07-09',
            '2019-07-10',
            '2019-07-11',
            '2019-07-12',
            '2019-07-13'
          ],
          type: ['全部', '一般媒体', '政府网站'],
          data: [
            [0, 0, 1982, 3396, 2412, 407, 859, 1784, 1300, 797, 717, 411, 284],
            [0, 0, 1918, 3324, 2354, 402, 853, 1769, 1294, 794, 711, 406, 284],
            [0, 0, 64, 72, 58, 5, 6, 15, 6, 3, 6, 5, 0]
          ]
        },
        web_type: {
          gov: 240,
          general: 14137
        },
        web_event_trend: {
          data: {
            trend: [
              {
                count: 0,
                date: '2019-07-01',
                points: ['开始点', '最低点']
              },
              {
                count: 0,
                date: '2019-07-02',
                points: []
              },
              {
                count: 1986,
                date: '2019-07-03',
                points: ['爆发点']
              },
              {
                count: 3401,
                date: '2019-07-04',
                points: ['高峰点']
              },
              {
                count: 2412,
                date: '2019-07-05',
                points: ['衰退点']
              },
              {
                count: 407,
                date: '2019-07-06',
                points: ['分界点']
              },
              {
                count: 863,
                date: '2019-07-07',
                points: ['爆发点']
              },
              {
                count: 1789,
                date: '2019-07-08',
                points: ['高峰点']
              },
              {
                count: 1304,
                date: '2019-07-09',
                points: ['结束点']
              }
            ],
            timeline: [
              {
                article: {
                  medias: ['新民晚报', '新浪', '和讯网'],
                  source_name: '新民晚报',
                  source_url:
                    'https://finance.sina.com.cn/stock/relnews/cn/2019-07-03/doc-ihytcerm1054436.shtml',
                  title: '新城控股董事长王某因猥亵9岁女童被采取强制措施'
                },
                date: '2019-07-03',
                points: ['爆发点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '新民晚报',
                    source_url:
                      'https://finance.sina.com.cn/stock/relnews/cn/2019-07-03/doc-ihytcerm1054436.shtml',
                    title: '新城控股董事长王某因猥亵9岁女童被采取强制措施'
                  },
                  {
                    media: '搜狐',
                    source_url: 'http://www.sohu.com/a/324646603_323328#comment_area',
                    title: '董事长猥亵女童被抓，新城控股连夜变更董事长，结果新任是他儿子'
                  },
                  {
                    media: '搜狐',
                    source_url: 'http://www.sohu.com/a/324694700_639898',
                    title: '从慈善家到涉嫌猥亵9岁女童的恶魔：新城控股原董事长王振华的AB面'
                  }
                ],
                state: '爆发期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['搜狐', '和讯网', '新浪'],
                  source_name: '搜狐',
                  source_url: 'http://www.sohu.com/a/324694700_639898',
                  title: '从慈善家到涉嫌猥亵9岁女童的恶魔：新城控股原董事长王振华的AB面'
                },
                date: '2019-07-04',
                points: ['高峰点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '搜狐',
                    source_url: 'http://www.sohu.com/a/324694700_639898',
                    title: '从慈善家到涉嫌猥亵9岁女童的恶魔：新城控股原董事长王振华的AB面'
                  },
                  {
                    media: '搜狐',
                    source_url: 'http://www.sohu.com/a/324792615_377096',
                    title: '王振华被刑拘！新城控股连夜换帅早盘跌停，存资金链断裂危机'
                  },
                  {
                    media: '中国新闻',
                    source_url: 'http://www.chinanews.com/sh/2019/07-05/8884659.shtml',
                    title: '伸向女童的魔爪暗藏罪恶利益链？家长和孩子如何防范'
                  }
                ],
                state: '蔓延期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['中国新闻', '搜狐', '桂林生活网'],
                  source_name: '中国新闻',
                  source_url: 'http://www.chinanews.com/sh/2019/07-05/8884659.shtml',
                  title: '伸向女童的魔爪暗藏罪恶利益链？家长和孩子如何防范'
                },
                date: '2019-07-05',
                points: ['衰退点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['和讯网', '证券日报', '搜狐'],
                  source_name: '和讯网',
                  source_url: 'https://stock.hexun.com/2019-07-06/197761424.html',
                  title: '新城控股连续两日跌停 沪股通逆势少量抄底'
                },
                date: '2019-07-06',
                points: ['分界点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['搜狐', '百家号'],
                  source_name: '搜狐',
                  source_url: 'http://www.sohu.com/a/325257410_100280645',
                  title: '面对儿童性侵沉默就是纵容'
                },
                date: '2019-07-07',
                points: ['爆发点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '搜狐',
                    source_url: 'http://www.sohu.com/a/325257410_100280645',
                    title: '面对儿童性侵沉默就是纵容'
                  },
                  {
                    media: '百家号',
                    source_url:
                      'https://baijiahao.baidu.com/s?id=1638486384001207806&wfr=spider&for=pc',
                    title: '雪上加霜！新城控股三日市值蒸发260亿，机构预测股价或继续下跌'
                  },
                  {
                    media: '百家号',
                    source_url:
                      'https://baijiahao.baidu.com/s?id=1638483902619333490&wfr=spider&for=pc',
                    title: '新城控股公关负责人回应质疑：经营活动正常开展'
                  }
                ],
                state: '爆发期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['百家号', '新京报', '新浪'],
                  source_name: '百家号',
                  source_url:
                    'https://baijiahao.baidu.com/s?id=1638483902619333490&wfr=spider&for=pc',
                  title: '新城控股公关负责人回应质疑：经营活动正常开展'
                },
                date: '2019-07-08',
                points: ['高峰点', '衰退点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '百家号',
                    source_url:
                      'https://baijiahao.baidu.com/s?id=1638483902619333490&wfr=spider&for=pc',
                    title: '新城控股公关负责人回应质疑：经营活动正常开展'
                  },
                  {
                    media: '百家号',
                    source_url:
                      'http://baijiahao.baidu.com/s?id=1638497404011531654&wfr=spider&for=pc',
                    title: '新城“黑天鹅”事件5天后港股股价回升 背后有香港大佬出手护盘？'
                  },
                  {
                    media: '百家号',
                    source_url:
                      'http://baijiahao.baidu.com/s?id=1638559662405807382&wfr=spider&for=pc',
                    title: '房企老板猥亵案续：王振华辞职仍为实控人，新城继续建吾悦广场'
                  }
                ],
                state: '衰退期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['百家号', '搜狐', '网易'],
                  source_name: '百家号',
                  source_url:
                    'http://baijiahao.baidu.com/s?id=1638559662405807382&wfr=spider&for=pc',
                  title: '房企老板猥亵案续：王振华辞职仍为实控人，新城继续建吾悦广场'
                },
                date: '2019-07-08',
                points: ['结束点'],
                type: 'point'
              }
            ]
          },
          isIncubation: true
        },
        web_main_platform: [
          {
            media: '东方财富网',
            total: 533,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 1, 32, 126, 93, 8, 32, 62, 86, 37, 38, 4, 14]
            },
            article: [
              {
                title: '新城控股董秘被约谈？回应：不属实 系先前约定 ',
                source_url: 'http://finance.eastmoney.com/a/201907041169676960.html'
              },
              {
                title: '新城控股董秘被约谈？公司回应：不属实 系先前约定 ',
                source_url: 'http://finance.eastmoney.com/a/201907041169687766.html'
              },
              {
                title: '新城控股发公开信致歉：深感歉意与不安 ',
                source_url: 'http://finance.eastmoney.com/news/1349,201907051170749530.html'
              }
            ]
          },
          {
            media: 'ZAKER',
            total: 460,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [4, 9, 88, 150, 84, 23, 12, 16, 36, 13, 14, 5, 6]
            },
            article: [
              {
                title: '震惊！新城控股董事长王振华因猥亵女童被抓，网友直接爆粗口',
                source_url: 'http://www.myzaker.com/article/5d1db8611bc8e027080001fb/'
              },
              {
                title: '新城控股董事长王振华被刑拘，其子王晓松推选为新董事长 ',
                source_url: 'http://www.myzaker.com/article/5d1cbc938e9f096202204098/'
              },
              {
                title: '新城董事长被采取强制措施 高管表示不方便接听电话 ',
                source_url: 'http://www.myzaker.com/article/5d1c758777ac64700a478af2/'
              }
            ]
          },
          {
            media: '新浪网',
            total: 346,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 80, 78, 47, 12, 20, 34, 26, 19, 12, 9, 9]
            },
            article: [
              {
                title: '新城控股内部人士:董事长涉嫌猥亵女童消息正在核实',
                source_url:
                  'https://finance.sina.com.cn/stock/roll/2019-07-03/doc-ihytcerm1066673.shtml'
              },
              {
                title: '王振华被撤销上海政协委员资格 实地探访新城控股总部|新城控股',
                source_url: 'https://news.sina.com.cn/c/2019-07-08/doc-ihytcitm0586478.shtml'
              },
              {
                title: '港股新城悦暴跌23% 新城控股董事长涉嫌猥亵女童属实',
                source_url:
                  'https://finance.sina.com.cn/stock/hkstock/marketalerts/2019-07-03/doc-ihytcerm1064577.shtml'
              }
            ]
          },
          {
            media: '潮州人新闻网',
            total: 311,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 83, 71, 45, 8, 15, 25, 27, 20, 14, 2, 1]
            },
            article: [
              {
                title: '新城控股内部人士:董事长涉嫌猥亵女童消息正在核实 ',
                source_url: 'http://www.czrnews.com/gushi/813736.html'
              },
              {
                title: '新城控股董事长涉嫌猥亵女童属实 新城系在香港暴跌 ',
                source_url: 'http://www.czrnews.com/caijing/813760.html'
              },
              {
                title: '新城控股：王振华因猥亵女童被拘 其子王晓松接任 ',
                source_url: 'http://www.czrnews.com/shehui/814468.html'
              }
            ]
          },
          {
            media: '海泡新闻网',
            total: 280,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 80, 55, 33, 4, 14, 28, 29, 21, 13, 2, 1]
            },
            article: [
              {
                title: '港股新城悦暴跌23% 新城控股董事长涉嫌猥亵女童属实 ',
                source_url: 'http://www.haipaoapp.com/cj/810020.html'
              },
              {
                title: '新城控股内部人士:董事长涉嫌猥亵女童消息正在核实 ',
                source_url: 'http://www.haipaoapp.com/gs/810044.html'
              },
              {
                title: '上市公司董事长涉嫌猥亵9岁女童 股票大跌 ',
                source_url: 'http://www.haipaoapp.com/sh/810162.html'
              }
            ]
          },
          {
            media: '鹏洋网络',
            total: 225,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 24, 128, 0, 0, 3, 13, 14, 19, 24, 0, 0]
            },
            article: [
              {
                title: '股价连续3个跌停板后终于着陆 新城控股“拯救计划”初见成效 ',
                source_url: 'http://www.jlzxxw.com/xwzx/show-86467.html'
              },
              {
                title: '股价连续3个跌停板后终于着陆 新城控股“拯救计划”初见成效 ',
                source_url: 'http://www.lzzxxw.com/xwzx/show-87554.html'
              },
              {
                title: '股价连续3个跌停板后终于着陆 新城控股“拯救计划”初见成效 ',
                source_url: 'http://www.btxwzx.com/xwzx/show-84605.html'
              }
            ]
          },
          {
            media: '乐居',
            total: 221,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 2, 2, 26, 0, 19, 81, 44, 33, 10, 4, 0]
            },
            article: [
              {
                title: '涉嫌“猥亵”女童、王振华被撤销上海市政协委员资格 ',
                source_url:
                  'http://bj.leju.com/news/2019-07-09/14496554249921133064303.shtml#wt_source=newshp_news_23'
              },
              {
                title: '太子火速上位，49小时后的新城控股终于回应了 ',
                source_url:
                  'http://wh.leju.com/news/2019-07-05/16376552827524521704952.shtml#wt_source=newshp_news_50'
              },
              {
                title: '太子火速上位，49小时后的新城控股终于回应了 ',
                source_url:
                  'http://wh.leju.com/news/2019-07-05/16376552827524521704952.shtml#wt_source=newshp_news_43'
              }
            ]
          },
          {
            media: '网易',
            total: 208,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 31, 35, 34, 16, 20, 26, 13, 13, 9, 1, 10]
            },
            article: [
              {
                title: '王振华猥亵女童被刑拘 致开盘跌停股民到总部询问',
                source_url: 'https://news.163.com/19/0704/10/EJ80JJMP0001899N.html'
              },
              {
                title: '新城控股董秘被约谈？回应：不属实 系先前约定',
                source_url: 'https://money.163.com/19/0704/18/EJ8UIEJ8002580S6.html'
              },
              {
                title: '新城控股董秘被约谈?公司回应:不属实 系先前约定',
                source_url: 'https://news.163.com/19/0704/18/EJ8U0RV00001875P.html'
              }
            ]
          },
          {
            media: '慈溪新闻网',
            total: 204,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 49, 31, 25, 9, 16, 22, 20, 17, 12, 2, 1]
            },
            article: [
              {
                title: '港股新城悦暴跌23% 新城控股董事长涉嫌猥亵女童属实 ',
                source_url: 'http://www.cicaero.com/cj/810020.html'
              },
              {
                title: '新城控股内部人士:董事长涉嫌猥亵女童消息正在核实 ',
                source_url: 'http://www.cicaero.com/gs/810044.html'
              },
              {
                title: '新城控股董事长涉嫌猥亵女童 两家新城系在香港暴跌 ',
                source_url: 'http://www.cicaero.com/cj/810070.html'
              }
            ]
          },
          {
            media: '中国经济网',
            total: 201,
            trend: {
              keys: [
                '2019-07-01',
                '2019-07-02',
                '2019-07-03',
                '2019-07-04',
                '2019-07-05',
                '2019-07-06',
                '2019-07-07',
                '2019-07-08',
                '2019-07-09',
                '2019-07-10',
                '2019-07-11',
                '2019-07-12',
                '2019-07-13'
              ],
              data: [0, 0, 11, 72, 60, 6, 3, 17, 9, 15, 2, 6, 0]
            },
            article: [
              {
                title: '王振华猥亵女童被刑拘 致开盘跌停股民到总部询问',
                source_url: 'http://m.ce.cn/sh/sgg/201907/04/t20190704_32524449.shtml'
              },
              {
                title: '董事长猥亵9岁女童 律师:或处5年以下有期徒刑',
                source_url: 'http://m.ce.cn/sh/sgg/201907/03/t20190703_32517352.shtml'
              },
              {
                title: '王振华涉嫌猥亵被刑拘 新城控股A股开盘直封跌停 逾280万手封单',
                source_url: 'http://m.ce.cn/gs/gd/201907/04/t20190704_32528955.shtml'
              }
            ]
          }
        ],
        web_key_media_trend: {
          keys: [
            '2019-07-03',
            '2019-07-04',
            '2019-07-05',
            '2019-07-06',
            '2019-07-07',
            '2019-07-08',
            '2019-07-09'
          ],
          data: [
            [
              {
                media: 'ZAKER',
                count: 88
              },
              {
                media: '潮州人新闻网',
                count: 83
              },
              {
                media: '新浪网',
                count: 80
              },
              {
                media: '海泡新闻网',
                count: 80
              },
              {
                media: '投资之家',
                count: 54
              },
              {
                media: '慈溪新闻网',
                count: 42
              },
              {
                media: '金融界',
                count: 42
              },
              {
                media: '黄山新闻',
                count: 41
              },
              {
                media: '东方财富网',
                count: 32
              },
              {
                media: '网易',
                count: 31
              }
            ],
            [
              {
                media: 'ZAKER',
                count: 150
              },
              {
                media: '鹏洋网络',
                count: 128
              },
              {
                media: '东方财富网',
                count: 126
              },
              {
                media: '新浪网',
                count: 78
              },
              {
                media: '中国经济网',
                count: 72
              },
              {
                media: '潮州人新闻网',
                count: 71
              },
              {
                media: '海泡新闻网',
                count: 55
              },
              {
                media: '和讯网',
                count: 54
              },
              {
                media: '搜狐',
                count: 51
              },
              {
                media: '投资之家',
                count: 46
              }
            ],
            [
              {
                media: '和讯网',
                count: 3
              },
              {
                media: 'ZAKER',
                count: 84
              },
              {
                media: '中国经济网',
                count: 60
              },
              {
                media: '新浪网',
                count: 47
              },
              {
                media: '潮州人新闻网',
                count: 45
              },
              {
                media: '金融界',
                count: 41
              },
              {
                media: '交通信息网',
                count: 37
              },
              {
                media: '和讯网',
                count: 34
              },
              {
                media: '网易',
                count: 34
              },
              {
                media: '海泡新闻网',
                count: 33
              }
            ],
            [
              {
                media: 'ZAKER',
                count: 23
              },
              {
                media: '网易',
                count: 16
              },
              {
                media: '新浪网',
                count: 12
              },
              {
                media: '微言网',
                count: 10
              },
              {
                media: '慈溪新闻网',
                count: 9
              },
              {
                media: '红歌会网',
                count: 9
              },
              {
                media: '东方财富网',
                count: 8
              },
              {
                media: '中国经济网',
                count: 6
              },
              {
                media: '中国经营网',
                count: 6
              },
              {
                media: '和讯网',
                count: 6
              }
            ],
            [
              {
                media: '凤凰网',
                count: 51
              },
              {
                media: '东方财富网',
                count: 32
              },
              {
                media: '港股那点事',
                count: 25
              },
              {
                media: '乐居',
                count: 19
              },
              {
                media: '新浪网',
                count: 20
              },
              {
                media: '网易',
                count: 20
              },
              {
                media: '上游新闻网',
                count: 16
              },
              {
                media: '临沂房产网',
                count: 16
              },
              {
                media: '爱财经新闻网',
                count: 16
              },
              {
                media: '财经新闻网',
                count: 14
              }
            ],
            [
              {
                media: '乐居',
                count: 81
              },
              {
                media: '一本财经网',
                count: 68
              },
              {
                media: '东方财富网',
                count: 62
              },
              {
                media: '百度',
                count: 49
              },
              {
                media: '新浪网',
                count: 34
              },
              {
                media: '百博网',
                count: 32
              },
              {
                media: '大众网',
                count: 26
              },
              {
                media: '网易',
                count: 26
              },
              {
                media: '财经网',
                count: 26
              },
              {
                media: '汉丰网',
                count: 24
              }
            ],
            [
              {
                media: '东方财富网',
                count: 86
              },
              {
                media: '乐居',
                count: 44
              },
              {
                media: 'ZAKER',
                count: 36
              },
              {
                media: '上游新闻',
                count: 34
              },
              {
                media: '海泡新闻网',
                count: 29
              },
              {
                media: '潮州人新闻网',
                count: 27
              },
              {
                media: '新浪网',
                count: 26
              },
              {
                media: '红歌会网',
                count: 24
              },
              {
                media: '慈溪新闻网',
                count: 20
              },
              {
                media: '爱财经新闻网',
                count: 17
              }
            ]
          ],
          desc: ['首次爆发', '达到顶峰', '开始衰退', '进入潜伏', '二次爆发', '到达顶峰', '结束时间']
        },
        web_spread_path: {
          name: 'ZAKER',
          children: [
            {
              name: '中国军网',
              children: [
                {
                  name: '688财经网',
                  children: [
                    {
                      name: '红商网',
                      children: [
                        {
                          name: '95女性网',
                          children: [
                            {
                              name: '酷基金',
                              children: [
                                {
                                  name: '农民热线',
                                  children: [
                                    {
                                      name: '青橘网',
                                      children: [
                                        {
                                          name: '洛阳市公安局交通警察支队车管所',
                                          children: [
                                            {
                                              name: '网贷东方',
                                              children: [
                                                {
                                                  name: '600云',
                                                  children: [
                                                    {
                                                      name: '中国知网',
                                                      children: [
                                                        {
                                                          name: '桂林生活网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '津云',
                                                          children: []
                                                        },
                                                        {
                                                          name: '江门邑网通',
                                                          children: []
                                                        },
                                                        {
                                                          name: '江门广播电视台',
                                                          children: []
                                                        },
                                                        {
                                                          name: '广视网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '新时互联网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '生活在线网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '今日中国',
                                                          children: []
                                                        },
                                                        {
                                                          name: '邯郸之窗',
                                                          children: []
                                                        },
                                                        {
                                                          name: '恩施网络电视',
                                                          children: []
                                                        },
                                                        {
                                                          name: '湖南龙网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '南方周末',
                                                          children: []
                                                        },
                                                        {
                                                          name: '昭平在线',
                                                          children: []
                                                        },
                                                        {
                                                          name: '晨鸿新闻网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '中日资讯网',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '地方网',
                                                      children: [
                                                        {
                                                          name: '华闻网',
                                                          children: [
                                                            {
                                                              name: '青岛信息网',
                                                              children: []
                                                            },
                                                            {
                                                              name: '永州网',
                                                              children: []
                                                            }
                                                          ]
                                                        },
                                                        {
                                                          name: '新时社网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '武侯区君少软件开发工作室',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '股窜网',
                                                      children: [
                                                        {
                                                          name: '法制生活网',
                                                          children: [
                                                            {
                                                              name: '企业党建参考网',
                                                              children: []
                                                            }
                                                          ]
                                                        },
                                                        {
                                                          name: '中国焦点在线',
                                                          children: []
                                                        },
                                                        {
                                                          name: '在线法律咨询',
                                                          children: []
                                                        },
                                                        {
                                                          name: '国青早报网',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '聚焦天津',
                                                      children: [
                                                        {
                                                          name: '曲靖新闻网',
                                                          children: [
                                                            {
                                                              name: '安顺在线',
                                                              children: []
                                                            },
                                                            {
                                                              name: '房前线',
                                                              children: []
                                                            },
                                                            {
                                                              name: '红云创投',
                                                              children: []
                                                            }
                                                          ]
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '商会中国',
                                                      children: [
                                                        {
                                                          name: '生活晨报',
                                                          children: [
                                                            {
                                                              name: '个人自考',
                                                              children: []
                                                            },
                                                            {
                                                              name: '运营商世界网',
                                                              children: []
                                                            }
                                                          ]
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '临沂新闻中心',
                                                      children: []
                                                    },
                                                    {
                                                      name: '南京新闻',
                                                      children: []
                                                    },
                                                    {
                                                      name: '孝感市关心下一代工作委员会官方网站',
                                                      children: []
                                                    },
                                                    {
                                                      name: '新疆平安网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '沂蒙晚报网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '中国广告门户网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '大中华新闻网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '溧水人民网',
                                                      children: []
                                                    }
                                                  ]
                                                },
                                                {
                                                  name: '移民11网',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '湖南民生在线',
                                              children: [
                                                {
                                                  name: '四川手机报',
                                                  children: [
                                                    {
                                                      name: '丹阳新闻网',
                                                      children: [
                                                        {
                                                          name: '周天秘史网',
                                                          children: [
                                                            {
                                                              name: '站长在线',
                                                              children: []
                                                            },
                                                            {
                                                              name: '西双版纳报',
                                                              children: []
                                                            },
                                                            {
                                                              name: '共享单车网',
                                                              children: []
                                                            }
                                                          ]
                                                        },
                                                        {
                                                          name: '澳门月刊',
                                                          children: []
                                                        },
                                                        {
                                                          name: 'Techweb',
                                                          children: []
                                                        },
                                                        {
                                                          name: '中国新闻监督网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '睢宁县司法行政网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '海东时报',
                                                          children: []
                                                        },
                                                        {
                                                          name: '今日惠州网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '紫荆网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '中原经济区网',
                                                          children: []
                                                        },
                                                        {
                                                          name: '著名深圳律师',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '顶尖财经网',
                                                      children: [
                                                        {
                                                          name: '生活信息网',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '视觉内蒙古',
                                                      children: [
                                                        {
                                                          name: '家居资讯博览',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: 'www.gushidaoshi.com',
                                                      children: [
                                                        {
                                                          name: '张家口新闻网',
                                                          children: []
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      name: '灵通资讯网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '点金新闻',
                                                      children: []
                                                    },
                                                    {
                                                      name: '寻风网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '中国企业新闻观察网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '阳湖网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '赤壁新闻门户网站',
                                                      children: []
                                                    },
                                                    {
                                                      name: '东北网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '惠众科技网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '价值中国网',
                                                      children: []
                                                    }
                                                  ]
                                                }
                                              ]
                                            },
                                            {
                                              name: '甘肃锐城律师事务所',
                                              children: [
                                                {
                                                  name: '一点阳光',
                                                  children: []
                                                },
                                                {
                                                  name: '魔游',
                                                  children: []
                                                },
                                                {
                                                  name: '基督时报',
                                                  children: []
                                                },
                                                {
                                                  name: '西陆网',
                                                  children: []
                                                },
                                                {
                                                  name: '华股财经',
                                                  children: []
                                                },
                                                {
                                                  name: '西安商网',
                                                  children: []
                                                },
                                                {
                                                  name: '名字汇网',
                                                  children: []
                                                },
                                                {
                                                  name: '优财网',
                                                  children: []
                                                },
                                                {
                                                  name: '洛阳房产网',
                                                  children: []
                                                },
                                                {
                                                  name: '泉舜刑辩量刑律师',
                                                  children: []
                                                },
                                                {
                                                  name: '瑞亚网',
                                                  children: []
                                                },
                                                {
                                                  name: '义乌市思君电子商务商行',
                                                  children: []
                                                },
                                                {
                                                  name: '爱本子网',
                                                  children: []
                                                },
                                                {
                                                  name: '今题网',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '焦作网',
                                              children: [
                                                {
                                                  name: '环球金融网',
                                                  children: []
                                                },
                                                {
                                                  name: '通信信息报',
                                                  children: []
                                                },
                                                {
                                                  name: '成都商业地产新闻中心',
                                                  children: []
                                                },
                                                {
                                                  name: '金湖新闻网',
                                                  children: []
                                                },
                                                {
                                                  name: '龙讯财经',
                                                  children: []
                                                },
                                                {
                                                  name: '燕山网',
                                                  children: []
                                                },
                                                {
                                                  name: '阳光网',
                                                  children: []
                                                },
                                                {
                                                  name: '英语文化交流',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '虹口新闻网',
                                              children: [
                                                {
                                                  name: '觅桃网影像黄页',
                                                  children: [
                                                    {
                                                      name: '中国菏泽网',
                                                      children: []
                                                    },
                                                    {
                                                      name: '菏泽日报',
                                                      children: []
                                                    }
                                                  ]
                                                }
                                              ]
                                            },
                                            {
                                              name: 'IT时代网',
                                              children: [
                                                {
                                                  name: '溧阳市新昌初级中学',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '写信啦',
                                              children: [
                                                {
                                                  name: '句容热线网',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '公益时报网',
                                              children: [
                                                {
                                                  name: '汇客云',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '高唐广播电视网',
                                              children: [
                                                {
                                                  name: '七台河日报',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '全国党媒',
                                              children: [
                                                {
                                                  name: '广告主',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '韩国日用品店',
                                              children: []
                                            },
                                            {
                                              name: '株洲新闻在线',
                                              children: []
                                            },
                                            {
                                              name: '重庆商报',
                                              children: []
                                            },
                                            {
                                              name: '魔快网络',
                                              children: []
                                            },
                                            {
                                              name: '闪新闻',
                                              children: []
                                            },
                                            {
                                              name: '龙头企业',
                                              children: []
                                            },
                                            {
                                              name: '中国法制维权网',
                                              children: []
                                            },
                                            {
                                              name: '三亚广电局',
                                              children: []
                                            },
                                            {
                                              name: '南京日报',
                                              children: []
                                            },
                                            {
                                              name: '魅力神州',
                                              children: []
                                            },
                                            {
                                              name: '逗趣网',
                                              children: []
                                            },
                                            {
                                              name: '星辰在线',
                                              children: []
                                            },
                                            {
                                              name: '湘西网',
                                              children: []
                                            },
                                            {
                                              name: '中华私募股权投资网',
                                              children: []
                                            },
                                            {
                                              name: '东营港',
                                              children: []
                                            },
                                            {
                                              name: '青年教育网',
                                              children: []
                                            },
                                            {
                                              name: '财富动力网',
                                              children: []
                                            },
                                            {
                                              name: '高温资讯网',
                                              children: []
                                            },
                                            {
                                              name: '新西兰先驱报中文网',
                                              children: []
                                            },
                                            {
                                              name: '132教育资讯网',
                                              children: []
                                            },
                                            {
                                              name: '企业新闻网',
                                              children: []
                                            },
                                            {
                                              name: '贵州网',
                                              children: []
                                            },
                                            {
                                              name: '零壹财经',
                                              children: []
                                            },
                                            {
                                              name: '51金融圈',
                                              children: []
                                            },
                                            {
                                              name: '瑞丽购物网',
                                              children: []
                                            },
                                            {
                                              name: '西部开发报社',
                                              children: []
                                            },
                                            {
                                              name: '金刺猬',
                                              children: []
                                            },
                                            {
                                              name: '中国金融观察网',
                                              children: []
                                            },
                                            {
                                              name: '乐享天翼',
                                              children: []
                                            },
                                            {
                                              name: '宁夏广播电视台',
                                              children: []
                                            },
                                            {
                                              name: '参考经济网',
                                              children: []
                                            },
                                            {
                                              name: '瓦房店市民网',
                                              children: []
                                            },
                                            {
                                              name: '富海商易',
                                              children: []
                                            },
                                            {
                                              name: '孝感网',
                                              children: []
                                            },
                                            {
                                              name: '创典房产',
                                              children: []
                                            },
                                            {
                                              name: '山西法制与社会新闻网',
                                              children: []
                                            },
                                            {
                                              name: '必维网',
                                              children: []
                                            },
                                            {
                                              name: '犟驴网',
                                              children: []
                                            },
                                            {
                                              name: '8天网',
                                              children: []
                                            },
                                            {
                                              name: '城关在线',
                                              children: []
                                            },
                                            {
                                              name: '温州市妙果寺',
                                              children: []
                                            },
                                            {
                                              name: '雅典宫',
                                              children: []
                                            },
                                            {
                                              name: '聚佳资讯网',
                                              children: []
                                            },
                                            {
                                              name: '扬州新凯元酒店用品',
                                              children: []
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      name: '趣要发',
                                      children: []
                                    },
                                    {
                                      name: 'AI财经社',
                                      children: []
                                    },
                                    {
                                      name: '新媒之家',
                                      children: []
                                    },
                                    {
                                      name: '法妞问答',
                                      children: []
                                    },
                                    {
                                      name: '洱海新闻网',
                                      children: []
                                    },
                                    {
                                      name: '绵阳新闻网',
                                      children: []
                                    },
                                    {
                                      name: '中国传媒网',
                                      children: []
                                    },
                                    {
                                      name: '北京物业网',
                                      children: []
                                    },
                                    {
                                      name: '分类信息网',
                                      children: []
                                    },
                                    {
                                      name: '异或新闻网',
                                      children: []
                                    },
                                    {
                                      name: '新粤网',
                                      children: []
                                    },
                                    {
                                      name: '超级生活网',
                                      children: []
                                    },
                                    {
                                      name: '长信丽三角平台',
                                      children: []
                                    },
                                    {
                                      name: '临沂房产网',
                                      children: []
                                    },
                                    {
                                      name: '农业信息网',
                                      children: []
                                    },
                                    {
                                      name: '珠海热线',
                                      children: []
                                    },
                                    {
                                      name: '江西电视台官网',
                                      children: []
                                    },
                                    {
                                      name: '老辰光',
                                      children: []
                                    },
                                    {
                                      name: '安阳市宇硕商务咨询有限公司',
                                      children: []
                                    },
                                    {
                                      name: '昆廷网',
                                      children: []
                                    },
                                    {
                                      name: '楼讯网',
                                      children: []
                                    },
                                    {
                                      name: '眉山日报',
                                      children: []
                                    },
                                    {
                                      name: '天天财经网',
                                      children: []
                                    },
                                    {
                                      name: '联合早报',
                                      children: []
                                    },
                                    {
                                      name: '福建网络广播电视台',
                                      children: []
                                    },
                                    {
                                      name: '益阳新闻网',
                                      children: []
                                    },
                                    {
                                      name: '第一金融网',
                                      children: []
                                    },
                                    {
                                      name: '华声网',
                                      children: []
                                    },
                                    {
                                      name: '北海房地产交易网',
                                      children: []
                                    },
                                    {
                                      name: '壹微百应',
                                      children: []
                                    },
                                    {
                                      name: '千寻学术网',
                                      children: []
                                    },
                                    {
                                      name: '有志者事竟成也',
                                      children: []
                                    },
                                    {
                                      name: '防城港新闻网',
                                      children: []
                                    },
                                    {
                                      name: '广东律师网',
                                      children: []
                                    },
                                    {
                                      name: '要闻急报',
                                      children: []
                                    },
                                    {
                                      name: '热点生活资讯网',
                                      children: []
                                    },
                                    {
                                      name: '新芽',
                                      children: []
                                    },
                                    {
                                      name: '易配网',
                                      children: []
                                    },
                                    {
                                      name: '质监新闻网',
                                      children: []
                                    },
                                    {
                                      name: '新闻热文',
                                      children: []
                                    },
                                    {
                                      name: '个性够味网',
                                      children: []
                                    },
                                    {
                                      name: '北京美食网',
                                      children: []
                                    },
                                    {
                                      name: '鳳凰華人資訊網',
                                      children: []
                                    },
                                    {
                                      name: '世纪金融网',
                                      children: []
                                    },
                                    {
                                      name: '衡阳广电网',
                                      children: []
                                    },
                                    {
                                      name: '米塔理财网',
                                      children: []
                                    },
                                    {
                                      name: '鲁恒建筑',
                                      children: []
                                    },
                                    {
                                      name: '湖南城市生活网',
                                      children: []
                                    },
                                    {
                                      name: '南宁热线',
                                      children: []
                                    },
                                    {
                                      name: 'www.jichentv.com',
                                      children: []
                                    },
                                    {
                                      name: '一诺工作室',
                                      children: []
                                    },
                                    {
                                      name: '呼和浩特新闻',
                                      children: []
                                    },
                                    {
                                      name: '财经精选',
                                      children: []
                                    },
                                    {
                                      name: '众筹之家',
                                      children: []
                                    },
                                    {
                                      name: '国泰环球',
                                      children: []
                                    },
                                    {
                                      name: '热血网',
                                      children: []
                                    },
                                    {
                                      name: '阳泉网络广播电视台',
                                      children: []
                                    },
                                    {
                                      name: '华人头条',
                                      children: []
                                    },
                                    {
                                      name: '第一波生活网',
                                      children: []
                                    },
                                    {
                                      name: '呼和浩特人民广播电台',
                                      children: []
                                    },
                                    {
                                      name: '蒙阴信息港',
                                      children: []
                                    },
                                    {
                                      name: '怡家新闻网',
                                      children: []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              name: '稻谷资讯网',
                              children: []
                            },
                            {
                              name: '延安信息港',
                              children: []
                            },
                            {
                              name: '广东资讯网',
                              children: []
                            },
                            {
                              name: '镇江资讯网',
                              children: []
                            },
                            {
                              name: '第一商业信息网',
                              children: []
                            },
                            {
                              name: '中新法治网',
                              children: []
                            },
                            {
                              name: '中国双创',
                              children: []
                            },
                            {
                              name: '嘉兴人',
                              children: []
                            },
                            {
                              name: '聚牛网',
                              children: []
                            },
                            {
                              name: '四川新闻网',
                              children: []
                            },
                            {
                              name: '中房网',
                              children: []
                            },
                            {
                              name: '迅视财经网',
                              children: []
                            },
                            {
                              name: '六五新闻网',
                              children: []
                            },
                            {
                              name: '四川建设网',
                              children: []
                            },
                            {
                              name: '成都新闻网',
                              children: []
                            },
                            {
                              name: '巴比特',
                              children: []
                            },
                            {
                              name: '遵义市广播电视台',
                              children: []
                            },
                            {
                              name: '凤梨财经',
                              children: []
                            },
                            {
                              name: '六安新闻网',
                              children: []
                            },
                            {
                              name: '快乐指针',
                              children: []
                            },
                            {
                              name: '微财经',
                              children: []
                            },
                            {
                              name: '广东商城网',
                              children: []
                            },
                            {
                              name: '金评媒',
                              children: []
                            },
                            {
                              name: '五二八BTC',
                              children: []
                            },
                            {
                              name: '搜链财经',
                              children: []
                            },
                            {
                              name: '股市动态分析周刊',
                              children: []
                            },
                            {
                              name: '???安区全丰货运代理部',
                              children: []
                            },
                            {
                              name: '海内知己',
                              children: []
                            },
                            {
                              name: '大功率LED网',
                              children: []
                            },
                            {
                              name: '精英网',
                              children: []
                            },
                            {
                              name: '靖江科普网',
                              children: []
                            },
                            {
                              name: '河南在线',
                              children: []
                            },
                            {
                              name: 'OFweek',
                              children: []
                            },
                            {
                              name: '闽商网',
                              children: []
                            },
                            {
                              name: 'www.xf0594.com',
                              children: []
                            },
                            {
                              name: '三农网',
                              children: []
                            },
                            {
                              name: '博兴之窗',
                              children: []
                            },
                            {
                              name: '成长365',
                              children: []
                            },
                            {
                              name: '中国市场新闻网',
                              children: []
                            },
                            {
                              name: '全球新能源网',
                              children: []
                            },
                            {
                              name: '山东财经网',
                              children: []
                            },
                            {
                              name: '夜灯网',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '母婴街',
                          children: [
                            {
                              name: '北京青年报数字报',
                              children: []
                            },
                            {
                              name: '中青在线',
                              children: []
                            },
                            {
                              name: '财讯网',
                              children: []
                            },
                            {
                              name: '硕文公考网',
                              children: []
                            },
                            {
                              name: '夜晚网',
                              children: []
                            },
                            {
                              name: '贵港新闻网',
                              children: []
                            },
                            {
                              name: '中国西藏网',
                              children: []
                            },
                            {
                              name: '山西青年报',
                              children: []
                            },
                            {
                              name: '四方资源网',
                              children: []
                            },
                            {
                              name: '北国网',
                              children: []
                            },
                            {
                              name: '楚州新闻信息中心',
                              children: []
                            },
                            {
                              name: '综治广角正义之声网',
                              children: []
                            },
                            {
                              name: '99娱乐资讯',
                              children: []
                            },
                            {
                              name: '广东新闻网',
                              children: []
                            },
                            {
                              name: '中国包公传媒网',
                              children: []
                            },
                            {
                              name: '同花顺经济网',
                              children: []
                            },
                            {
                              name: '宜春都市网',
                              children: []
                            },
                            {
                              name: '信网',
                              children: []
                            },
                            {
                              name: '企业时代报',
                              children: []
                            },
                            {
                              name: '道州网',
                              children: []
                            },
                            {
                              name: '西江传媒',
                              children: []
                            },
                            {
                              name: '名城苏州',
                              children: []
                            },
                            {
                              name: '福建省工商业联合会',
                              children: []
                            },
                            {
                              name: '青海新闻网',
                              children: []
                            },
                            {
                              name: '0818资讯',
                              children: []
                            },
                            {
                              name: '民主资讯网',
                              children: []
                            },
                            {
                              name: '中国林业新闻网',
                              children: []
                            },
                            {
                              name: '益阳新闻',
                              children: []
                            },
                            {
                              name: '益阳在线',
                              children: []
                            },
                            {
                              name: '海口网社区',
                              children: []
                            },
                            {
                              name: '首都政法综治网',
                              children: []
                            },
                            {
                              name: '嘻嘻哈哈分类信息',
                              children: []
                            },
                            {
                              name: '云南公务员考试网',
                              children: []
                            },
                            {
                              name: '四川公务员考试网',
                              children: []
                            },
                            {
                              name: '甘肃公务员考试网',
                              children: []
                            },
                            {
                              name: '天津公务员考试网',
                              children: []
                            },
                            {
                              name: '山西公务员考试网',
                              children: []
                            },
                            {
                              name: '运城长安网',
                              children: []
                            },
                            {
                              name: '三元新闻网',
                              children: []
                            },
                            {
                              name: '彩铃网',
                              children: []
                            },
                            {
                              name: '山东新闻网',
                              children: []
                            },
                            {
                              name: '乐昌市安全生产监督管理局',
                              children: []
                            },
                            {
                              name: '一带一路城市网',
                              children: []
                            },
                            {
                              name: '党建时讯网',
                              children: []
                            },
                            {
                              name: '龙报',
                              children: []
                            },
                            {
                              name: '中国贪官网',
                              children: []
                            },
                            {
                              name: '企业报道',
                              children: []
                            },
                            {
                              name: '中国家庭应急网',
                              children: []
                            },
                            {
                              name: '检察日报社',
                              children: []
                            },
                            {
                              name: '中外法制网',
                              children: []
                            },
                            {
                              name: '中直党建网',
                              children: []
                            },
                            {
                              name: '大侠网',
                              children: []
                            },
                            {
                              name: '南通生活网',
                              children: []
                            },
                            {
                              name: '沂蒙法制网',
                              children: []
                            },
                            {
                              name: '无锡太湖明珠',
                              children: []
                            },
                            {
                              name: '达州市中级人民法院',
                              children: []
                            },
                            {
                              name: '中国法院网',
                              children: []
                            },
                            {
                              name: '济宁新闻网',
                              children: []
                            },
                            {
                              name: '上海政法综治网',
                              children: []
                            },
                            {
                              name: '中国企业公民网',
                              children: []
                            },
                            {
                              name: '瑞昌市公共资源交易中心',
                              children: []
                            },
                            {
                              name: '三秦网',
                              children: []
                            },
                            {
                              name: '政协手机报网',
                              children: []
                            },
                            {
                              name: '互联分享',
                              children: []
                            },
                            {
                              name: '中国军网-首页国际国内热点',
                              children: []
                            },
                            {
                              name: '企业头条',
                              children: []
                            },
                            {
                              name: '光讯168',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '第一财经周刊内容网站',
                          children: [
                            {
                              name: '余杭妇女网',
                              children: []
                            },
                            {
                              name: '女性之声',
                              children: []
                            },
                            {
                              name: '清风文学',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '中录电视',
                          children: [
                            {
                              name: '泰兴生活网',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '宁夏新闻网',
                          children: [
                            {
                              name: '福建体育直播',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '福布斯中国',
                          children: []
                        },
                        {
                          name: '齐鲁晚报',
                          children: []
                        },
                        {
                          name: '广州日报社',
                          children: []
                        },
                        {
                          name: '小城天长',
                          children: []
                        },
                        {
                          name: '海疆在线',
                          children: []
                        },
                        {
                          name: '星岛环球网',
                          children: []
                        },
                        {
                          name: '看看新闻网',
                          children: []
                        },
                        {
                          name: '晨兴汇票助手',
                          children: []
                        },
                        {
                          name: '中金网',
                          children: []
                        },
                        {
                          name: '江苏快讯网',
                          children: []
                        },
                        {
                          name: '金投网',
                          children: []
                        },
                        {
                          name: '上饶网',
                          children: []
                        },
                        {
                          name: '微啦',
                          children: []
                        },
                        {
                          name: '中国贸易金融网',
                          children: []
                        },
                        {
                          name: '苏州新闻网',
                          children: []
                        },
                        {
                          name: '厦门蓝房网',
                          children: []
                        },
                        {
                          name: '南华财经',
                          children: []
                        },
                        {
                          name: '云南发布',
                          children: []
                        },
                        {
                          name: '威海市会展办公室',
                          children: []
                        },
                        {
                          name: '中国联合新闻网',
                          children: []
                        },
                        {
                          name: '解放日报',
                          children: []
                        },
                        {
                          name: '上海观察',
                          children: []
                        },
                        {
                          name: '溧阳市卫生监督所',
                          children: []
                        }
                      ]
                    },
                    {
                      name: '镇江新区在线',
                      children: [
                        {
                          name: '外汇谷',
                          children: [
                            {
                              name: '今日报道网',
                              children: []
                            },
                            {
                              name: '本来网',
                              children: []
                            },
                            {
                              name: '兰州晨报',
                              children: []
                            },
                            {
                              name: '上海人民广播电台',
                              children: []
                            },
                            {
                              name: '祖国新闻网',
                              children: []
                            },
                            {
                              name: '华西都市网',
                              children: []
                            },
                            {
                              name: '众赢投顾',
                              children: []
                            },
                            {
                              name: '华声在线',
                              children: []
                            },
                            {
                              name: '新快网',
                              children: []
                            },
                            {
                              name: '号外财经网',
                              children: []
                            },
                            {
                              name: '叩富网',
                              children: []
                            },
                            {
                              name: '山东经济网',
                              children: []
                            },
                            {
                              name: '蓝梦网',
                              children: []
                            },
                            {
                              name: '湖南经济网',
                              children: []
                            },
                            {
                              name: '汉德网',
                              children: []
                            },
                            {
                              name: '青岛西海岸新闻网',
                              children: []
                            },
                            {
                              name: '山东卫生新闻网',
                              children: []
                            },
                            {
                              name: '盲人之家',
                              children: []
                            },
                            {
                              name: '重庆房产网',
                              children: []
                            },
                            {
                              name: '深思网',
                              children: []
                            },
                            {
                              name: '都市头条网',
                              children: []
                            },
                            {
                              name: '句容第一房产网',
                              children: []
                            },
                            {
                              name: '温商社区',
                              children: []
                            },
                            {
                              name: '中国法治网',
                              children: []
                            },
                            {
                              name: '七台河网络广播电视台',
                              children: []
                            },
                            {
                              name: '泉州网',
                              children: []
                            },
                            {
                              name: '中山网新闻',
                              children: []
                            },
                            {
                              name: '湖北新闻网',
                              children: []
                            },
                            {
                              name: '中国民生播报网',
                              children: []
                            },
                            {
                              name: '中国城市网',
                              children: []
                            },
                            {
                              name: '天下金融网',
                              children: []
                            },
                            {
                              name: '中评网',
                              children: []
                            },
                            {
                              name: '九龙虫',
                              children: []
                            },
                            {
                              name: '比特巴',
                              children: []
                            },
                            {
                              name: '中都网',
                              children: []
                            },
                            {
                              name: '天山网',
                              children: []
                            },
                            {
                              name: '杭州在线',
                              children: []
                            },
                            {
                              name: '昕薇网',
                              children: []
                            },
                            {
                              name: '秋天童话',
                              children: []
                            },
                            {
                              name: '技术分享网',
                              children: []
                            },
                            {
                              name: '集微网',
                              children: []
                            },
                            {
                              name: '山东电视',
                              children: []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: '天和网',
                      children: [
                        {
                          name: '我的财讯',
                          children: [
                            {
                              name: '东楚网',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '聊聊网',
                          children: []
                        },
                        {
                          name: '成都商务网',
                          children: []
                        },
                        {
                          name: '澎湃新闻',
                          children: []
                        },
                        {
                          name: '经济日报',
                          children: []
                        },
                        {
                          name: '证券日报网',
                          children: []
                        }
                      ]
                    },
                    {
                      name: '广东展翅南方网',
                      children: [
                        {
                          name: '因分享而精彩',
                          children: [
                            {
                              name: '乐视财经',
                              children: []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: '精选知识网',
                      children: []
                    },
                    {
                      name: '来宾新闻网',
                      children: []
                    },
                    {
                      name: '三亚日报',
                      children: []
                    },
                    {
                      name: '温哥华天空',
                      children: []
                    },
                    {
                      name: '凯图新闻网',
                      children: []
                    },
                    {
                      name: '财经新闻网',
                      children: []
                    },
                    {
                      name: '广东知明律师事务所',
                      children: []
                    },
                    {
                      name: '跑商网',
                      children: []
                    },
                    {
                      name: '大同生活网',
                      children: []
                    },
                    {
                      name: '慈溪新闻网',
                      children: []
                    },
                    {
                      name: '新浪网',
                      children: []
                    },
                    {
                      name: '凤凰网',
                      children: []
                    },
                    {
                      name: '扬子晚报',
                      children: []
                    },
                    {
                      name: '财经',
                      children: []
                    },
                    {
                      name: '手机财经',
                      children: []
                    },
                    {
                      name: '网易',
                      children: []
                    },
                    {
                      name: '潮州人新闻网',
                      children: []
                    },
                    {
                      name: '潇湘晨报网',
                      children: []
                    },
                    {
                      name: '黄山新闻',
                      children: []
                    },
                    {
                      name: '海泡新闻网',
                      children: []
                    },
                    {
                      name: '腾讯网',
                      children: []
                    },
                    {
                      name: '每日经济网',
                      children: []
                    },
                    {
                      name: '每经网',
                      children: []
                    },
                    {
                      name: '投资之家',
                      children: []
                    },
                    {
                      name: '中国经济网',
                      children: []
                    },
                    {
                      name: '爱财界',
                      children: []
                    },
                    {
                      name: '金融界',
                      children: []
                    },
                    {
                      name: '北京时间',
                      children: []
                    },
                    {
                      name: '搜狐',
                      children: []
                    },
                    {
                      name: '中财网',
                      children: []
                    },
                    {
                      name: '中国财经信息网',
                      children: []
                    },
                    {
                      name: '优悦优品',
                      children: []
                    },
                    {
                      name: '财联社',
                      children: []
                    },
                    {
                      name: '东方网',
                      children: []
                    },
                    {
                      name: '财经365',
                      children: []
                    },
                    {
                      name: '深蓝财经',
                      children: []
                    },
                    {
                      name: '搜狐焦点',
                      children: []
                    },
                    {
                      name: '21经济网',
                      children: []
                    },
                    {
                      name: '长江网',
                      children: []
                    },
                    {
                      name: '新京报网',
                      children: []
                    },
                    {
                      name: '西部网',
                      children: []
                    },
                    {
                      name: '界面新闻',
                      children: []
                    },
                    {
                      name: '经济视点网',
                      children: []
                    },
                    {
                      name: '房天下',
                      children: []
                    },
                    {
                      name: '华西都市报',
                      children: []
                    },
                    {
                      name: '散户吧',
                      children: []
                    },
                    {
                      name: '江淮网',
                      children: []
                    },
                    {
                      name: '和讯网',
                      children: []
                    },
                    {
                      name: '玉轩天天快报',
                      children: []
                    },
                    {
                      name: '股市热点',
                      children: []
                    },
                    {
                      name: '黑龙江网',
                      children: []
                    },
                    {
                      name: '黔讯网',
                      children: []
                    },
                    {
                      name: '上游新闻',
                      children: []
                    },
                    {
                      name: '安徽新地产交易网',
                      children: []
                    },
                    {
                      name: '北城网',
                      children: []
                    },
                    {
                      name: '温州新闻网',
                      children: []
                    },
                    {
                      name: '股城网',
                      children: []
                    },
                    {
                      name: '大众网',
                      children: []
                    },
                    {
                      name: '慧拓科技',
                      children: []
                    },
                    {
                      name: '猎云网',
                      children: []
                    },
                    {
                      name: '维其信息',
                      children: []
                    },
                    {
                      name: '乐居',
                      children: []
                    },
                    {
                      name: '中宏网',
                      children: []
                    },
                    {
                      name: '东北新闻网',
                      children: []
                    },
                    {
                      name: '钱讯网',
                      children: []
                    },
                    {
                      name: '易搜企业信息库',
                      children: []
                    },
                    {
                      name: '经理人网',
                      children: []
                    },
                    {
                      name: '济南买房网',
                      children: []
                    },
                    {
                      name: '郑州晚报',
                      children: []
                    },
                    {
                      name: '时事新闻',
                      children: []
                    },
                    {
                      name: '亿欧网',
                      children: []
                    },
                    {
                      name: '速新闻',
                      children: []
                    },
                    {
                      name: '赢家财富网',
                      children: []
                    },
                    {
                      name: '中国常州网',
                      children: []
                    },
                    {
                      name: '爱房网',
                      children: []
                    },
                    {
                      name: '赤峰工程建设信息网',
                      children: []
                    },
                    {
                      name: '闽南网',
                      children: []
                    },
                    {
                      name: '成都电视台',
                      children: []
                    },
                    {
                      name: '信赢天下',
                      children: []
                    },
                    {
                      name: '汕尾信息网',
                      children: []
                    },
                    {
                      name: '机械资源网',
                      children: []
                    },
                    {
                      name: '博览黄页',
                      children: []
                    },
                    {
                      name: '珍草堂信息库',
                      children: []
                    },
                    {
                      name: '信托披露',
                      children: []
                    },
                    {
                      name: '大广网',
                      children: []
                    },
                    {
                      name: '云息',
                      children: []
                    },
                    {
                      name: '深圳鸿远盛世',
                      children: []
                    },
                    {
                      name: '财经网',
                      children: []
                    },
                    {
                      name: '粤信时代财经网',
                      children: []
                    },
                    {
                      name: '中新经纬',
                      children: []
                    },
                    {
                      name: '富途牛牛',
                      children: []
                    },
                    {
                      name: '交通信息网',
                      children: []
                    },
                    {
                      name: '联商网',
                      children: []
                    },
                    {
                      name: '大河网',
                      children: []
                    },
                    {
                      name: 'AcFun弹幕视频网',
                      children: []
                    },
                    {
                      name: '中国建设报',
                      children: []
                    },
                    {
                      name: '金融投资网',
                      children: []
                    },
                    {
                      name: '海峡网',
                      children: []
                    },
                    {
                      name: '上海信息港',
                      children: []
                    },
                    {
                      name: '河南新闻网络中心',
                      children: []
                    },
                    {
                      name: '中国房地产网',
                      children: []
                    },
                    {
                      name: '巴巴头头像',
                      children: []
                    },
                    {
                      name: '长春新闻网',
                      children: []
                    },
                    {
                      name: '新安房产网',
                      children: []
                    },
                    {
                      name: '中国小康网',
                      children: []
                    },
                    {
                      name: '房掌柜',
                      children: []
                    },
                    {
                      name: '天河热线',
                      children: []
                    },
                    {
                      name: '东方都市网',
                      children: []
                    },
                    {
                      name: '时讯网',
                      children: []
                    },
                    {
                      name: '沃财富',
                      children: []
                    },
                    {
                      name: '海吉斯',
                      children: []
                    },
                    {
                      name: '望江在线',
                      children: []
                    },
                    {
                      name: '财经时报',
                      children: []
                    },
                    {
                      name: '新三板在线',
                      children: []
                    },
                    {
                      name: '钛媒体',
                      children: []
                    },
                    {
                      name: '小熊在线',
                      children: []
                    },
                    {
                      name: '京晨晚报网',
                      children: []
                    },
                    {
                      name: '亿房网',
                      children: []
                    },
                    {
                      name: '内蒙古晨网',
                      children: []
                    },
                    {
                      name: '民生网',
                      children: []
                    },
                    {
                      name: '山东传媒',
                      children: []
                    },
                    {
                      name: '仁寿信息网',
                      children: []
                    },
                    {
                      name: '鲁网',
                      children: []
                    },
                    {
                      name: 'www.gzhshoulu.wang',
                      children: []
                    },
                    {
                      name: '人民政协网',
                      children: []
                    },
                    {
                      name: '弹幕网',
                      children: []
                    },
                    {
                      name: '城市联合网',
                      children: []
                    },
                    {
                      name: '十堰广电网',
                      children: []
                    },
                    {
                      name: '中国经营网',
                      children: []
                    },
                    {
                      name: '机器网',
                      children: []
                    },
                    {
                      name: '游侠股市',
                      children: []
                    },
                    {
                      name: '爱好者吧',
                      children: []
                    },
                    {
                      name: '时尚人民资讯网',
                      children: []
                    },
                    {
                      name: '泗水网络电视台',
                      children: []
                    },
                    {
                      name: '嗨牛财经',
                      children: []
                    },
                    {
                      name: '华夏时报网',
                      children: []
                    },
                    {
                      name: '成都商报',
                      children: []
                    },
                    {
                      name: '房讯网',
                      children: []
                    },
                    {
                      name: '综投网',
                      children: []
                    },
                    {
                      name: '我的李尚客',
                      children: []
                    },
                    {
                      name: '济宁网',
                      children: []
                    },
                    {
                      name: '中工网',
                      children: []
                    },
                    {
                      name: '河南百度',
                      children: []
                    },
                    {
                      name: '爱国网',
                      children: []
                    },
                    {
                      name: '南国早报网',
                      children: []
                    },
                    {
                      name: '南方财富网',
                      children: []
                    },
                    {
                      name: '西安文明网',
                      children: []
                    },
                    {
                      name: '中华网河北',
                      children: []
                    },
                    {
                      name: '融禅网',
                      children: []
                    },
                    {
                      name: '四川在线',
                      children: []
                    },
                    {
                      name: '常州中吴网',
                      children: []
                    },
                    {
                      name: '江西网络广播电视台',
                      children: []
                    },
                    {
                      name: '燃点网',
                      children: []
                    },
                    {
                      name: '妈妈社区',
                      children: []
                    },
                    {
                      name: '中国新闻网',
                      children: []
                    },
                    {
                      name: '徐州商务网',
                      children: []
                    },
                    {
                      name: '鹏洋网络',
                      children: []
                    },
                    {
                      name: '商业价值',
                      children: []
                    },
                    {
                      name: '麦扑网',
                      children: []
                    },
                    {
                      name: '中国公益新闻网',
                      children: []
                    },
                    {
                      name: '赤峰新闻网',
                      children: []
                    },
                    {
                      name: '东莞时间网',
                      children: []
                    },
                    {
                      name: '网销在线',
                      children: []
                    },
                    {
                      name: '自然奇观美景',
                      children: []
                    },
                    {
                      name: '百战网',
                      children: []
                    },
                    {
                      name: '广西城市生活网',
                      children: []
                    },
                    {
                      name: '福居百汇房产',
                      children: []
                    },
                    {
                      name: '看西部',
                      children: []
                    },
                    {
                      name: '辽电情报网',
                      children: []
                    },
                    {
                      name: '世界杰出华人华商协会信息网',
                      children: []
                    },
                    {
                      name: '华云网',
                      children: []
                    },
                    {
                      name: '长沙晚报网',
                      children: []
                    },
                    {
                      name: '光明网',
                      children: []
                    },
                    {
                      name: '心系网',
                      children: []
                    },
                    {
                      name: '中国发展网',
                      children: []
                    },
                    {
                      name: '金山网',
                      children: []
                    },
                    {
                      name: '读特',
                      children: []
                    },
                    {
                      name: '四海网',
                      children: []
                    },
                    {
                      name: '微言网',
                      children: []
                    },
                    {
                      name: 'fx112财经网',
                      children: []
                    },
                    {
                      name: '九龙网',
                      children: []
                    },
                    {
                      name: '襄网',
                      children: []
                    },
                    {
                      name: '互金狗',
                      children: []
                    },
                    {
                      name: '侠客网',
                      children: []
                    },
                    {
                      name: 'www.jldysz.cn',
                      children: []
                    },
                    {
                      name: '鄂西北在线',
                      children: []
                    },
                    {
                      name: '江苏消费网',
                      children: []
                    },
                    {
                      name: '鼎铭网',
                      children: []
                    },
                    {
                      name: '心悦供求网',
                      children: []
                    },
                    {
                      name: '企业网',
                      children: []
                    },
                    {
                      name: '八宝宝网',
                      children: []
                    },
                    {
                      name: '中原新闻网',
                      children: []
                    },
                    {
                      name: '合肥热线',
                      children: []
                    },
                    {
                      name: '新华报业',
                      children: []
                    },
                    {
                      name: '华律网',
                      children: []
                    },
                    {
                      name: '虎嗅网',
                      children: []
                    },
                    {
                      name: '环球网',
                      children: []
                    },
                    {
                      name: '公众网',
                      children: []
                    },
                    {
                      name: '江苏卫视',
                      children: []
                    },
                    {
                      name: '蜂鸟财院',
                      children: []
                    },
                    {
                      name: 'FJ随记',
                      children: []
                    },
                    {
                      name: '路透网',
                      children: []
                    },
                    {
                      name: '黔西南在线',
                      children: []
                    },
                    {
                      name: '财经报道网',
                      children: []
                    },
                    {
                      name: '艾奇体育用品',
                      children: []
                    },
                    {
                      name: '张家口在线',
                      children: []
                    },
                    {
                      name: '家电消费网-导购',
                      children: []
                    },
                    {
                      name: '投资之窗',
                      children: []
                    },
                    {
                      name: '思源社区',
                      children: []
                    },
                    {
                      name: '山东广播网',
                      children: []
                    },
                    {
                      name: '荆楚网',
                      children: []
                    },
                    {
                      name: '报告诉',
                      children: []
                    },
                    {
                      name: '酷锐网',
                      children: []
                    },
                    {
                      name: '创头条',
                      children: []
                    },
                    {
                      name: '新华财经',
                      children: []
                    },
                    {
                      name: '成都全搜索',
                      children: []
                    },
                    {
                      name: '宠物吧',
                      children: []
                    },
                    {
                      name: '正义网',
                      children: []
                    },
                    {
                      name: '浙江新闻网',
                      children: []
                    },
                    {
                      name: '鲁西网',
                      children: []
                    },
                    {
                      name: '青岛日报',
                      children: []
                    },
                    {
                      name: '楚秀网',
                      children: []
                    },
                    {
                      name: '咱们的家乡',
                      children: []
                    },
                    {
                      name: '时空网',
                      children: []
                    },
                    {
                      name: '中网资讯',
                      children: []
                    },
                    {
                      name: '今讯网',
                      children: []
                    },
                    {
                      name: '道天下',
                      children: []
                    },
                    {
                      name: '上海热线',
                      children: []
                    },
                    {
                      name: '商产网',
                      children: []
                    },
                    {
                      name: '龙基金官网',
                      children: []
                    },
                    {
                      name: '武汉律师咨询网',
                      children: []
                    },
                    {
                      name: '好律师网',
                      children: []
                    },
                    {
                      name: '中国江苏网',
                      children: []
                    },
                    {
                      name: '吴忠市残疾人联合会',
                      children: []
                    },
                    {
                      name: '我苏网',
                      children: []
                    },
                    {
                      name: '都市时报网',
                      children: []
                    },
                    {
                      name: '瞄股网',
                      children: []
                    },
                    {
                      name: '法律与生活网',
                      children: []
                    },
                    {
                      name: '固原在线',
                      children: []
                    },
                    {
                      name: '中国诚信观察网',
                      children: []
                    },
                    {
                      name: '消费参考',
                      children: []
                    },
                    {
                      name: '东至人网',
                      children: []
                    },
                    {
                      name: '天天在线',
                      children: []
                    },
                    {
                      name: '时事经济观察',
                      children: []
                    },
                    {
                      name: '黄岛房产网',
                      children: []
                    },
                    {
                      name: '银行信息港',
                      children: []
                    },
                    {
                      name: '数据新闻网',
                      children: []
                    },
                    {
                      name: '纽约在线',
                      children: []
                    },
                    {
                      name: '中国宁波网',
                      children: []
                    },
                    {
                      name: '美国中文网',
                      children: []
                    },
                    {
                      name: '广西新闻网',
                      children: []
                    },
                    {
                      name: '南京智能家庭影院装修设计专家',
                      children: []
                    },
                    {
                      name: '今日泉州网',
                      children: []
                    },
                    {
                      name: '安徽网',
                      children: []
                    },
                    {
                      name: '中国经济与法治网',
                      children: []
                    },
                    {
                      name: '人民网',
                      children: []
                    },
                    {
                      name: '华夏小康网',
                      children: []
                    },
                    {
                      name: '辽东网',
                      children: []
                    }
                  ]
                },
                {
                  name: '操盘手学习技巧',
                  children: [
                    {
                      name: '中国金融信息网',
                      children: [
                        {
                          name: '北京市中银（南京）律师事务所',
                          children: [
                            {
                              name: '温州市鹿城区五马黄炎饰品店',
                              children: [
                                {
                                  name: '张家口网络广播电视台',
                                  children: [
                                    {
                                      name: '西部监测预测网',
                                      children: [
                                        {
                                          name: '风险看吧股票博客',
                                          children: [
                                            {
                                              name: '华夏聚焦新闻网',
                                              children: []
                                            },
                                            {
                                              name: '问法网',
                                              children: []
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      name: '昭??市广播电视局',
                                      children: []
                                    },
                                    {
                                      name: '苏志宏的个人主页',
                                      children: []
                                    },
                                    {
                                      name: '北京文艺网',
                                      children: []
                                    },
                                    {
                                      name: '阿贵生活网',
                                      children: []
                                    },
                                    {
                                      name: '他不爱你',
                                      children: []
                                    },
                                    {
                                      name: '历城区汇川计算机信息服务中心',
                                      children: []
                                    },
                                    {
                                      name: '络安信息',
                                      children: []
                                    }
                                  ]
                                },
                                {
                                  name: ' 湛江新闻网',
                                  children: [
                                    {
                                      name: '记住吧',
                                      children: []
                                    },
                                    {
                                      name: '领域网',
                                      children: []
                                    },
                                    {
                                      name: '丹蒂诗品牌网',
                                      children: []
                                    }
                                  ]
                                },
                                {
                                  name: '全讯网2',
                                  children: [
                                    {
                                      name: '启智志成',
                                      children: []
                                    },
                                    {
                                      name: '邢台站长网',
                                      children: []
                                    }
                                  ]
                                },
                                {
                                  name: '西安晚报数字报',
                                  children: []
                                },
                                {
                                  name: '中国藏族网通',
                                  children: []
                                },
                                {
                                  name: '证金贵金属',
                                  children: []
                                },
                                {
                                  name: '军转网',
                                  children: []
                                },
                                {
                                  name: '主人公网',
                                  children: []
                                },
                                {
                                  name: '新安在线',
                                  children: []
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: '荔枝网',
                          children: [
                            {
                              name: '沂源红苹果',
                              children: [
                                {
                                  name: '西安网',
                                  children: [
                                    {
                                      name: '西本新干线',
                                      children: [
                                        {
                                          name: '佰佰安全网',
                                          children: []
                                        }
                                      ]
                                    },
                                    {
                                      name: '微澳网',
                                      children: []
                                    },
                                    {
                                      name: '青海羚网',
                                      children: []
                                    }
                                  ]
                                },
                                {
                                  name: '云法律网',
                                  children: []
                                },
                                {
                                  name: '赢商网',
                                  children: []
                                },
                                {
                                  name: '北戴河旅游网',
                                  children: []
                                }
                              ]
                            },
                            {
                              name: '三板富',
                              children: [
                                {
                                  name: '申城网',
                                  children: []
                                },
                                {
                                  name: '织毛衣母婴信息网',
                                  children: []
                                },
                                {
                                  name: '红旗网',
                                  children: []
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: '新文化网',
                          children: []
                        },
                        {
                          name: '焦作市防雷减灾管理局',
                          children: []
                        },
                        {
                          name: '中国四川新闻网',
                          children: []
                        },
                        {
                          name: '爱球网',
                          children: []
                        },
                        {
                          name: '仙居新闻网',
                          children: []
                        },
                        {
                          name: '学路上',
                          children: []
                        },
                        {
                          name: '酒泉网',
                          children: []
                        },
                        {
                          name: '爱客网',
                          children: []
                        },
                        {
                          name: '国盛科技',
                          children: []
                        },
                        {
                          name: '漳州市芗城区行政中心',
                          children: []
                        },
                        {
                          name: '阿薇薇空间',
                          children: []
                        },
                        {
                          name: '万信达信息服务',
                          children: []
                        },
                        {
                          name: '浙江在线',
                          children: []
                        },
                        {
                          name: '牛犊网',
                          children: []
                        },
                        {
                          name: '企东财讯',
                          children: []
                        },
                        {
                          name: '中国日报网',
                          children: []
                        },
                        {
                          name: '浙江网',
                          children: []
                        },
                        {
                          name: '无线泉州',
                          children: []
                        },
                        {
                          name: '泉视界',
                          children: []
                        },
                        {
                          name: '羊城晚报',
                          children: []
                        },
                        {
                          name: '海外网',
                          children: []
                        },
                        {
                          name: '趣明星',
                          children: []
                        },
                        {
                          name: '南通发布',
                          children: []
                        },
                        {
                          name: '深圳新闻网',
                          children: []
                        },
                        {
                          name: '童之梦游乐设备网',
                          children: []
                        },
                        {
                          name: '法人网',
                          children: []
                        },
                        {
                          name: '中华新闻网',
                          children: []
                        },
                        {
                          name: '楚天都市网',
                          children: []
                        },
                        {
                          name: '全球资源网',
                          children: []
                        },
                        {
                          name: '国旺电子',
                          children: []
                        },
                        {
                          name: '米资讯',
                          children: []
                        },
                        {
                          name: '观察者',
                          children: []
                        },
                        {
                          name: '宁波广电网',
                          children: []
                        },
                        {
                          name: '贸易经济网',
                          children: []
                        },
                        {
                          name: '红网',
                          children: []
                        },
                        {
                          name: '各界新闻网',
                          children: []
                        },
                        {
                          name: '新华网',
                          children: []
                        },
                        {
                          name: '铜陵新闻网',
                          children: []
                        },
                        {
                          name: '探索网',
                          children: []
                        },
                        {
                          name: '嗨滁州网',
                          children: []
                        },
                        {
                          name: '聊城新闻网',
                          children: []
                        },
                        {
                          name: '南京头条',
                          children: []
                        },
                        {
                          name: '中国经济瞭望周刊',
                          children: []
                        },
                        {
                          name: '80视点',
                          children: []
                        },
                        {
                          name: '民主与法制网',
                          children: []
                        },
                        {
                          name: '牛仔网',
                          children: []
                        },
                        {
                          name: '深圳盘子网',
                          children: []
                        },
                        {
                          name: '创新中国网',
                          children: []
                        },
                        {
                          name: '中国国际图书出版社',
                          children: []
                        },
                        {
                          name: '美丽绽放',
                          children: []
                        },
                        {
                          name: '尚一网-常德新闻网',
                          children: []
                        },
                        {
                          name: '国家医学教育发展中心',
                          children: []
                        },
                        {
                          name: '亳州房产网',
                          children: []
                        },
                        {
                          name: '邯郸新闻网',
                          children: []
                        },
                        {
                          name: '大美黑龙江',
                          children: []
                        },
                        {
                          name: '海都网',
                          children: []
                        },
                        {
                          name: '青田侨报',
                          children: []
                        },
                        {
                          name: '惠州房地产权威媒体',
                          children: []
                        },
                        {
                          name: '陕西工人报数字报',
                          children: []
                        },
                        {
                          name: '云南电视台',
                          children: []
                        },
                        {
                          name: '非常在线',
                          children: []
                        },
                        {
                          name: '灯塔财经',
                          children: []
                        },
                        {
                          name: '经济视野网',
                          children: []
                        },
                        {
                          name: '金乡网',
                          children: []
                        },
                        {
                          name: '汉网',
                          children: []
                        },
                        {
                          name: '台州商务网',
                          children: []
                        },
                        {
                          name: '淄博新闻在线',
                          children: []
                        },
                        {
                          name: '滨湖之都',
                          children: []
                        },
                        {
                          name: '永州水文信息网',
                          children: []
                        },
                        {
                          name: '福州12358',
                          children: []
                        },
                        {
                          name: '住在济南',
                          children: []
                        },
                        {
                          name: '早报网',
                          children: []
                        },
                        {
                          name: '陕西传媒网',
                          children: []
                        },
                        {
                          name: '九江电视台',
                          children: []
                        },
                        {
                          name: '丝路明珠网',
                          children: []
                        },
                        {
                          name: '全球成功网',
                          children: []
                        },
                        {
                          name: '人民法治网',
                          children: []
                        },
                        {
                          name: '营口热线',
                          children: []
                        },
                        {
                          name: '房政在线',
                          children: []
                        },
                        {
                          name: '中国法治',
                          children: []
                        },
                        {
                          name: '浙江高得乐太阳能',
                          children: []
                        },
                        {
                          name: '西安财经信息网',
                          children: []
                        },
                        {
                          name: '文明网',
                          children: []
                        },
                        {
                          name: '宝鸡新闻网',
                          children: []
                        },
                        {
                          name: '黑龙江网络广播电视台',
                          children: []
                        },
                        {
                          name: '斗鱼',
                          children: []
                        },
                        {
                          name: '芜湖新闻网',
                          children: []
                        },
                        {
                          name: '广州日报',
                          children: []
                        },
                        {
                          name: '湖南经济新闻网',
                          children: []
                        },
                        {
                          name: '聚金网',
                          children: []
                        },
                        {
                          name: '飞扬网',
                          children: []
                        },
                        {
                          name: '中国精英网',
                          children: []
                        },
                        {
                          name: '白银投资网',
                          children: []
                        },
                        {
                          name: '鹏愿',
                          children: []
                        },
                        {
                          name: '投资界',
                          children: []
                        },
                        {
                          name: '北晚新视觉',
                          children: []
                        },
                        {
                          name: '广州蓄电池回收网',
                          children: []
                        },
                        {
                          name: '戈尔网',
                          children: []
                        },
                        {
                          name: '未来网',
                          children: []
                        },
                        {
                          name: '名城西安',
                          children: []
                        },
                        {
                          name: '联合网',
                          children: []
                        },
                        {
                          name: '花领结-战友情！',
                          children: []
                        },
                        {
                          name: '每日金融',
                          children: []
                        },
                        {
                          name: '广州网',
                          children: []
                        },
                        {
                          name: '岳阳网',
                          children: []
                        },
                        {
                          name: '消费者报道',
                          children: []
                        },
                        {
                          name: '揭阳新闻网',
                          children: []
                        },
                        {
                          name: '珲春热线网',
                          children: []
                        },
                        {
                          name: '文件销毁',
                          children: []
                        },
                        {
                          name: '驻马店电视台',
                          children: []
                        },
                        {
                          name: '海南省浙江商会',
                          children: []
                        },
                        {
                          name: '邳州新闻网',
                          children: []
                        },
                        {
                          name: '全球资讯网',
                          children: []
                        },
                        {
                          name: '必应网',
                          children: []
                        },
                        {
                          name: '大河报',
                          children: []
                        },
                        {
                          name: '国信新闻网',
                          children: []
                        },
                        {
                          name: '三峡晚报',
                          children: []
                        },
                        {
                          name: '华图教育',
                          children: []
                        },
                        {
                          name: '巴酷商贸网',
                          children: []
                        },
                        {
                          name: '武汉资讯',
                          children: []
                        },
                        {
                          name: '罗山在线',
                          children: []
                        },
                        {
                          name: '临沂维拓恋房网',
                          children: []
                        },
                        {
                          name: '江北之窗',
                          children: []
                        },
                        {
                          name: '安庆E网',
                          children: []
                        },
                        {
                          name: '科创尚元',
                          children: []
                        },
                        {
                          name: '泰安交警信息网',
                          children: []
                        },
                        {
                          name: '微发信息网',
                          children: []
                        },
                        {
                          name: '昆仑策',
                          children: []
                        },
                        {
                          name: '金财网',
                          children: []
                        },
                        {
                          name: '阜宁新闻网',
                          children: []
                        },
                        {
                          name: '西部丝路',
                          children: []
                        },
                        {
                          name: 'www.v-cores.cn',
                          children: []
                        },
                        {
                          name: '梧州新闻网',
                          children: []
                        },
                        {
                          name: '涨姿势',
                          children: []
                        },
                        {
                          name: '怕输网',
                          children: []
                        },
                        {
                          name: '东浙网',
                          children: []
                        },
                        {
                          name: '在线网',
                          children: []
                        },
                        {
                          name: '青白江区益成建材经营部',
                          children: []
                        },
                        {
                          name: '中国建筑网',
                          children: []
                        },
                        {
                          name: '贵阳新闻网',
                          children: []
                        },
                        {
                          name: '央广网',
                          children: []
                        },
                        {
                          name: '中原网视',
                          children: []
                        },
                        {
                          name: '融站科技',
                          children: []
                        },
                        {
                          name: '四川网络广播电视台',
                          children: []
                        },
                        {
                          name: '阳光的点滴气味',
                          children: []
                        },
                        {
                          name: '陕西网',
                          children: []
                        },
                        {
                          name: '重庆青年报',
                          children: []
                        },
                        {
                          name: '天津今日头条',
                          children: []
                        },
                        {
                          name: '中国文明网',
                          children: []
                        },
                        {
                          name: '中华泰山网',
                          children: []
                        },
                        {
                          name: '德宏师范高等专科学校校工会(妇委会)',
                          children: []
                        },
                        {
                          name: '法制日报',
                          children: []
                        },
                        {
                          name: '钝角网',
                          children: []
                        },
                        {
                          name: '企商路',
                          children: []
                        },
                        {
                          name: '南方企业新闻网',
                          children: []
                        },
                        {
                          name: '北方企业新闻网',
                          children: []
                        },
                        {
                          name: '第一金融',
                          children: []
                        },
                        {
                          name: '时事一点通',
                          children: []
                        },
                        {
                          name: '北京皮革网',
                          children: []
                        },
                        {
                          name: '春生网',
                          children: []
                        },
                        {
                          name: '宁波都市网',
                          children: []
                        },
                        {
                          name: '商报网事',
                          children: []
                        },
                        {
                          name: '高陵网',
                          children: []
                        },
                        {
                          name: '法务网',
                          children: []
                        },
                        {
                          name: '人民健康报',
                          children: []
                        },
                        {
                          name: '学习大军',
                          children: []
                        },
                        {
                          name: '中原财富网',
                          children: []
                        },
                        {
                          name: '掌中九江',
                          children: []
                        },
                        {
                          name: '速途网',
                          children: []
                        },
                        {
                          name: '网商在线',
                          children: []
                        },
                        {
                          name: 'i天下网商',
                          children: []
                        },
                        {
                          name: '中国财经新闻',
                          children: []
                        },
                        {
                          name: '新湖南',
                          children: []
                        },
                        {
                          name: '香港卫视',
                          children: []
                        },
                        {
                          name: '砍柴网',
                          children: []
                        },
                        {
                          name: '盐淮网',
                          children: []
                        },
                        {
                          name: '湖北之窗',
                          children: []
                        },
                        {
                          name: '大众生活网',
                          children: []
                        },
                        {
                          name: '时代快报网',
                          children: []
                        },
                        {
                          name: '一米资讯',
                          children: []
                        },
                        {
                          name: '网客网',
                          children: []
                        },
                        {
                          name: '贵州沿河经济开发区',
                          children: []
                        },
                        {
                          name: '热点新闻网',
                          children: []
                        },
                        {
                          name: '中国产经网',
                          children: []
                        },
                        {
                          name: '奇闻',
                          children: []
                        },
                        {
                          name: '新鲜科技-综合',
                          children: []
                        },
                        {
                          name: '五八网',
                          children: []
                        },
                        {
                          name: '宿迁房产',
                          children: []
                        }
                      ]
                    },
                    {
                      name: '外汇天眼网',
                      children: [
                        {
                          name: '百行网',
                          children: [
                            {
                              name: '诸暨网',
                              children: [
                                {
                                  name: '黄山新闻网',
                                  children: [
                                    {
                                      name: '上海国际棉花交易中心',
                                      children: [
                                        {
                                          name: '全国工商联',
                                          children: [
                                            {
                                              name: 'i天津视窗',
                                              children: [
                                                {
                                                  name: '上饶新闻网',
                                                  children: []
                                                },
                                                {
                                                  name: '桶装水配送',
                                                  children: []
                                                },
                                                {
                                                  name: '大华网',
                                                  children: []
                                                },
                                                {
                                                  name: '中国消费商网',
                                                  children: []
                                                },
                                                {
                                                  name: '新东方健康网',
                                                  children: []
                                                },
                                                {
                                                  name: '中国校长网',
                                                  children: []
                                                },
                                                {
                                                  name: '泸州市建筑工程管理局',
                                                  children: []
                                                },
                                                {
                                                  name: '金融时报中文版',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '今晚看啥',
                                              children: [
                                                {
                                                  name: '沪闵在线',
                                                  children: []
                                                }
                                              ]
                                            }
                                          ]
                                        },
                                        {
                                          name: '条目网',
                                          children: [
                                            {
                                              name: '锡林郭勒盟妇幼保健院',
                                              children: [
                                                {
                                                  name: '大连新闻网',
                                                  children: []
                                                }
                                              ]
                                            },
                                            {
                                              name: '课度网',
                                              children: []
                                            },
                                            {
                                              name: '久爱攀枝花',
                                              children: []
                                            },
                                            {
                                              name: '安庆新闻资讯',
                                              children: []
                                            }
                                          ]
                                        },
                                        {
                                          name: '百博网',
                                          children: []
                                        },
                                        {
                                          name: '新蓝网',
                                          children: []
                                        },
                                        {
                                          name: '易网在线',
                                          children: []
                                        },
                                        {
                                          name: '中企互动',
                                          children: []
                                        },
                                        {
                                          name: '中国律师中介网',
                                          children: []
                                        },
                                        {
                                          name: '南阳事网',
                                          children: []
                                        },
                                        {
                                          name: '中国吉林网',
                                          children: []
                                        },
                                        {
                                          name: '西北在线网',
                                          children: []
                                        },
                                        {
                                          name: '安徽省工商业联合会',
                                          children: []
                                        },
                                        {
                                          name: '人民网海南视窗',
                                          children: []
                                        },
                                        {
                                          name: '中国行业经济网',
                                          children: []
                                        },
                                        {
                                          name: '中华商业网',
                                          children: []
                                        },
                                        {
                                          name: '曲阜网',
                                          children: []
                                        },
                                        {
                                          name: '全球经济热点网',
                                          children: []
                                        },
                                        {
                                          name: '厦门网',
                                          children: []
                                        },
                                        {
                                          name: '新时代资讯网',
                                          children: []
                                        },
                                        {
                                          name: '莆田网',
                                          children: []
                                        },
                                        {
                                          name: '丽水网',
                                          children: []
                                        },
                                        {
                                          name: '陕北资讯网',
                                          children: []
                                        },
                                        {
                                          name: '东南网',
                                          children: []
                                        },
                                        {
                                          name: '中国台湾网',
                                          children: []
                                        },
                                        {
                                          name: '中国政协传媒网',
                                          children: []
                                        },
                                        {
                                          name: '中国乡镇网',
                                          children: []
                                        },
                                        {
                                          name: '消费与法网',
                                          children: []
                                        },
                                        {
                                          name: '中国煤矿网',
                                          children: []
                                        },
                                        {
                                          name: '昌平网',
                                          children: []
                                        },
                                        {
                                          name: '科教网',
                                          children: []
                                        },
                                        {
                                          name: '湿地中国',
                                          children: []
                                        },
                                        {
                                          name: '法制与社会网',
                                          children: []
                                        },
                                        {
                                          name: '日月峡国家森林公园',
                                          children: []
                                        },
                                        {
                                          name: '北京昌平广播电视网',
                                          children: []
                                        },
                                        {
                                          name: '新闻资讯网',
                                          children: []
                                        },
                                        {
                                          name: '今日科学',
                                          children: []
                                        },
                                        {
                                          name: '北京著名刑事律师',
                                          children: []
                                        },
                                        {
                                          name: '深圳今日头条新闻网',
                                          children: []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  name: '财富入门网',
                                  children: [
                                    {
                                      name: '华兴本地新闻网',
                                      children: [
                                        {
                                          name: '为许一世沧情',
                                          children: [
                                            {
                                              name: '上海市总工会',
                                              children: [
                                                {
                                                  name: '中国乐清网',
                                                  children: []
                                                }
                                              ]
                                            }
                                          ]
                                        },
                                        {
                                          name: '我爱我家',
                                          children: []
                                        },
                                        {
                                          name: '每天快乐',
                                          children: []
                                        },
                                        {
                                          name: '医药观察家网',
                                          children: []
                                        },
                                        {
                                          name: '咪哚网',
                                          children: []
                                        },
                                        {
                                          name: '老人家',
                                          children: []
                                        },
                                        {
                                          name: '麦兜学习网',
                                          children: []
                                        },
                                        {
                                          name: '中资北方',
                                          children: []
                                        },
                                        {
                                          name: '回顾广东',
                                          children: []
                                        },
                                        {
                                          name: '蒲城纪检监察网',
                                          children: []
                                        },
                                        {
                                          name: '十点网',
                                          children: []
                                        },
                                        {
                                          name: '文成县新闻信息中心',
                                          children: []
                                        },
                                        {
                                          name: '潮州新闻网',
                                          children: []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  name: '兰州日报数字报',
                                  children: []
                                },
                                {
                                  name: '梦幻西游',
                                  children: []
                                },
                                {
                                  name: '证券市场红周刊',
                                  children: []
                                },
                                {
                                  name: '南海网',
                                  children: []
                                },
                                {
                                  name: '温州日报瓯网',
                                  children: []
                                },
                                {
                                  name: '炒股配资平台',
                                  children: []
                                },
                                {
                                  name: '朝装网',
                                  children: []
                                },
                                {
                                  name: '曲周新闻网',
                                  children: []
                                },
                                {
                                  name: '翁氏网',
                                  children: []
                                },
                                {
                                  name: '广西品房阁',
                                  children: []
                                },
                                {
                                  name: '商业时报',
                                  children: []
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: '中青网',
                          children: []
                        },
                        {
                          name: '格时财经',
                          children: []
                        },
                        {
                          name: '耀翔宝',
                          children: []
                        },
                        {
                          name: '福建新闻网',
                          children: []
                        },
                        {
                          name: '千龙网',
                          children: []
                        },
                        {
                          name: '唔哩头条',
                          children: []
                        },
                        {
                          name: '财报网',
                          children: []
                        },
                        {
                          name: '滁州新闻网',
                          children: []
                        },
                        {
                          name: '杭州生活网',
                          children: []
                        },
                        {
                          name: '中原网',
                          children: []
                        },
                        {
                          name: '西部都市网',
                          children: []
                        },
                        {
                          name: '广州参考',
                          children: []
                        },
                        {
                          name: '东莞市房地产业网',
                          children: []
                        },
                        {
                          name: '大众信息网',
                          children: []
                        },
                        {
                          name: '爱美丽网',
                          children: []
                        },
                        {
                          name: '梅州网',
                          children: []
                        },
                        {
                          name: '都爱网',
                          children: []
                        },
                        {
                          name: '证券之星',
                          children: []
                        },
                        {
                          name: 'FUND部落',
                          children: []
                        },
                        {
                          name: '洛阳在线',
                          children: []
                        },
                        {
                          name: '红歌会网',
                          children: []
                        },
                        {
                          name: '51网',
                          children: []
                        },
                        {
                          name: '日照网',
                          children: []
                        },
                        {
                          name: '华夏快报',
                          children: []
                        },
                        {
                          name: '山东周刊',
                          children: []
                        },
                        {
                          name: '中国财经界',
                          children: []
                        },
                        {
                          name: '法新网',
                          children: []
                        },
                        {
                          name: '西安热线',
                          children: []
                        },
                        {
                          name: '质量监督网',
                          children: []
                        },
                        {
                          name: '宝应网络电视台',
                          children: []
                        },
                        {
                          name: '海南热线',
                          children: []
                        },
                        {
                          name: '华商网',
                          children: []
                        },
                        {
                          name: '中公网校',
                          children: []
                        },
                        {
                          name: '平凉新闻网',
                          children: []
                        },
                        {
                          name: '中山热线',
                          children: []
                        },
                        {
                          name: '股融易',
                          children: []
                        },
                        {
                          name: '番茄财经',
                          children: []
                        },
                        {
                          name: '???茄财经',
                          children: []
                        },
                        {
                          name: '猎马网',
                          children: []
                        },
                        {
                          name: '盐城新闻网',
                          children: []
                        },
                        {
                          name: '信鸽拍卖交易网',
                          children: []
                        },
                        {
                          name: '新梦网',
                          children: []
                        },
                        {
                          name: '中原经济网',
                          children: []
                        },
                        {
                          name: '江苏经济网',
                          children: []
                        },
                        {
                          name: '王长波个人兴趣交流',
                          children: []
                        },
                        {
                          name: '兰格钢铁',
                          children: []
                        },
                        {
                          name: '艺恩网',
                          children: []
                        },
                        {
                          name: '青岛新闻网',
                          children: []
                        },
                        {
                          name: '巫溪网',
                          children: []
                        },
                        {
                          name: '法帮网',
                          children: []
                        },
                        {
                          name: '中传网',
                          children: []
                        },
                        {
                          name: '育儿在线',
                          children: []
                        },
                        {
                          name: '中国女网',
                          children: []
                        },
                        {
                          name: '每日新闻网',
                          children: []
                        },
                        {
                          name: '百纳网',
                          children: []
                        },
                        {
                          name: 'PC资源吧',
                          children: []
                        },
                        {
                          name: '突袭网',
                          children: []
                        },
                        {
                          name: 'DoNew',
                          children: []
                        },
                        {
                          name: '风火轮',
                          children: []
                        },
                        {
                          name: '金典内贸网',
                          children: []
                        },
                        {
                          name: '淮北新闻网',
                          children: []
                        },
                        {
                          name: '喷嚏网',
                          children: []
                        },
                        {
                          name: '读我网',
                          children: []
                        },
                        {
                          name: '京燕网',
                          children: []
                        },
                        {
                          name: '郑州经济发展网',
                          children: []
                        },
                        {
                          name: '龙腾网',
                          children: []
                        },
                        {
                          name: '一起飞目录',
                          children: []
                        },
                        {
                          name: '搜财网',
                          children: []
                        },
                        {
                          name: '圈点',
                          children: []
                        },
                        {
                          name: '快乐生活',
                          children: []
                        },
                        {
                          name: '临海IPTV',
                          children: []
                        },
                        {
                          name: '宜宾新闻网',
                          children: []
                        },
                        {
                          name: '晋网',
                          children: []
                        },
                        {
                          name: '朝阳永续Go',
                          children: []
                        },
                        {
                          name: '老财牛',
                          children: []
                        },
                        {
                          name: '横峰吧',
                          children: []
                        },
                        {
                          name: '黑龙江职业学院第二校区',
                          children: []
                        },
                        {
                          name: '舟山网-大海网',
                          children: []
                        },
                        {
                          name: '舟山大海网',
                          children: []
                        },
                        {
                          name: '公益中国',
                          children: []
                        },
                        {
                          name: '男人窝',
                          children: []
                        },
                        {
                          name: '新农村商报网',
                          children: []
                        },
                        {
                          name: '长城网',
                          children: []
                        },
                        {
                          name: '云新网',
                          children: []
                        },
                        {
                          name: '百姓维权网',
                          children: []
                        },
                        {
                          name: '记者沙龙网',
                          children: []
                        },
                        {
                          name: '青岛网络广播电视台',
                          children: []
                        },
                        {
                          name: '网贷天眼网',
                          children: []
                        },
                        {
                          name: '投融网',
                          children: []
                        },
                        {
                          name: '赣州新闻网',
                          children: []
                        },
                        {
                          name: '伪原创',
                          children: []
                        },
                        {
                          name: '哈尔滨日报',
                          children: []
                        },
                        {
                          name: '海峡都市报',
                          children: []
                        },
                        {
                          name: '深圳市宁江文化促进会',
                          children: []
                        },
                        {
                          name: '览潮网',
                          children: []
                        },
                        {
                          name: '中国长安网',
                          children: []
                        },
                        {
                          name: '看今朝网',
                          children: []
                        }
                      ]
                    }
                  ]
                },
                {
                  name: '东方财富网',
                  children: [
                    {
                      name: '艾格时间',
                      children: [
                        {
                          name: '万隆证券网',
                          children: [
                            {
                              name: '无忧岛',
                              children: [
                                {
                                  name: '中国国际电子商务中心广东站',
                                  children: [
                                    {
                                      name: 'DM67信息网',
                                      children: [
                                        {
                                          name: '神光财经',
                                          children: []
                                        },
                                        {
                                          name: '武城棉花信息网',
                                          children: []
                                        },
                                        {
                                          name: '天天小站',
                                          children: []
                                        },
                                        {
                                          name: '商丘网',
                                          children: []
                                        },
                                        {
                                          name: '肥城市一诺千言网络推广工作室',
                                          children: []
                                        },
                                        {
                                          name: '今日品牌',
                                          children: []
                                        },
                                        {
                                          name: '常信村百科网',
                                          children: []
                                        },
                                        {
                                          name: '温州房产门户网站',
                                          children: []
                                        },
                                        {
                                          name: '健康河南网',
                                          children: []
                                        },
                                        {
                                          name: '北京电视台',
                                          children: []
                                        },
                                        {
                                          name: '安徽医药卫生监督网',
                                          children: []
                                        },
                                        {
                                          name: '乳山热线',
                                          children: []
                                        },
                                        {
                                          name: '大手笔信息港',
                                          children: []
                                        },
                                        {
                                          name: '乌海电视台',
                                          children: []
                                        },
                                        {
                                          name: '湖北省交通运输厅',
                                          children: []
                                        },
                                        {
                                          name: '炫彩新闻网',
                                          children: []
                                        },
                                        {
                                          name: '沙县房地产信息网',
                                          children: []
                                        },
                                        {
                                          name: '南平市房地产管理信息网',
                                          children: []
                                        },
                                        {
                                          name: '建阳房地产信息网',
                                          children: []
                                        },
                                        {
                                          name: '环球经济网',
                                          children: []
                                        },
                                        {
                                          name: '茂名日报',
                                          children: []
                                        },
                                        {
                                          name: '中国联合商报',
                                          children: []
                                        },
                                        {
                                          name: '中国供求网',
                                          children: []
                                        },
                                        {
                                          name: '国际在线',
                                          children: []
                                        },
                                        {
                                          name: '百度',
                                          children: []
                                        },
                                        {
                                          name: '将乐房地产管理信息网',
                                          children: []
                                        },
                                        {
                                          name: '邵武房地产信息网',
                                          children: []
                                        },
                                        {
                                          name: '众筹时代',
                                          children: []
                                        },
                                        {
                                          name: '安青网',
                                          children: []
                                        },
                                        {
                                          name: '保险投资网',
                                          children: []
                                        },
                                        {
                                          name: '中国酷跑网',
                                          children: []
                                        },
                                        {
                                          name: '华人网',
                                          children: []
                                        },
                                        {
                                          name: '华讯新闻',
                                          children: []
                                        },
                                        {
                                          name: '第一产经网',
                                          children: []
                                        },
                                        {
                                          name: '财界网',
                                          children: []
                                        },
                                        {
                                          name: '都市网',
                                          children: []
                                        },
                                        {
                                          name: '汕尾日报社（善为网）',
                                          children: []
                                        },
                                        {
                                          name: '太原热线',
                                          children: []
                                        },
                                        {
                                          name: '风中落叶飘零',
                                          children: []
                                        },
                                        {
                                          name: '草根网',
                                          children: []
                                        },
                                        {
                                          name: '广州热线',
                                          children: []
                                        },
                                        {
                                          name: '南宁网',
                                          children: []
                                        },
                                        {
                                          name: '沧州网',
                                          children: []
                                        },
                                        {
                                          name: '合肥网',
                                          children: []
                                        },
                                        {
                                          name: '晚报网',
                                          children: []
                                        },
                                        {
                                          name: '威海信息港',
                                          children: []
                                        },
                                        {
                                          name: '云南热线',
                                          children: []
                                        },
                                        {
                                          name: '洛阳之窗',
                                          children: []
                                        },
                                        {
                                          name: '城市金融报',
                                          children: []
                                        },
                                        {
                                          name: '桂林网',
                                          children: []
                                        },
                                        {
                                          name: '城经网',
                                          children: []
                                        },
                                        {
                                          name: '星空观察网',
                                          children: []
                                        },
                                        {
                                          name: '经济报道网',
                                          children: []
                                        },
                                        {
                                          name: '今日财经',
                                          children: []
                                        },
                                        {
                                          name: '峨眉山房产网',
                                          children: []
                                        },
                                        {
                                          name: '中国经济新闻网',
                                          children: []
                                        },
                                        {
                                          name: '碧爱克丝布诺',
                                          children: []
                                        },
                                        {
                                          name: '太平洋财富网',
                                          children: []
                                        },
                                        {
                                          name: '农业工程期刊网',
                                          children: []
                                        },
                                        {
                                          name: '携景财富网',
                                          children: []
                                        },
                                        {
                                          name: '中国煤矿安全生产网',
                                          children: []
                                        },
                                        {
                                          name: '阜阳日报',
                                          children: []
                                        },
                                        {
                                          name: '莞讯网',
                                          children: []
                                        },
                                        {
                                          name: '遵义网',
                                          children: []
                                        },
                                        {
                                          name: '财知道',
                                          children: []
                                        },
                                        {
                                          name: '城市化网',
                                          children: []
                                        },
                                        {
                                          name: '大漠土艺',
                                          children: []
                                        },
                                        {
                                          name: '金融虎',
                                          children: []
                                        },
                                        {
                                          name: '爱爆料',
                                          children: []
                                        },
                                        {
                                          name: '滕州全媒体',
                                          children: []
                                        },
                                        {
                                          name: '长春市宽城区民政局婚姻登记处',
                                          children: []
                                        },
                                        {
                                          name: '国际金融报',
                                          children: []
                                        },
                                        {
                                          name: '建秋网',
                                          children: []
                                        },
                                        {
                                          name: '新闻观察网启业汇',
                                          children: []
                                        },
                                        {
                                          name: '龙岩第一社区',
                                          children: []
                                        },
                                        {
                                          name: '中国金融新闻网',
                                          children: []
                                        },
                                        {
                                          name: '上海金融新闻网',
                                          children: []
                                        },
                                        {
                                          name: '泉州新闻资讯',
                                          children: []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  name: '竞报体育',
                                  children: [
                                    {
                                      name: '云山恩施',
                                      children: []
                                    },
                                    {
                                      name: '法治周末',
                                      children: []
                                    }
                                  ]
                                },
                                {
                                  name: '百客网',
                                  children: []
                                },
                                {
                                  name: '盐城站长网',
                                  children: []
                                },
                                {
                                  name: 'PHP编程网',
                                  children: []
                                },
                                {
                                  name: '东东技术分享网',
                                  children: []
                                },
                                {
                                  name: '新媒体聚焦',
                                  children: []
                                },
                                {
                                  name: '温岭网',
                                  children: []
                                }
                              ]
                            },
                            {
                              name: '学网易',
                              children: []
                            },
                            {
                              name: '市场信息网',
                              children: []
                            },
                            {
                              name: '魅力中国网',
                              children: []
                            },
                            {
                              name: '常新网',
                              children: []
                            },
                            {
                              name: '糖酒产业网',
                              children: []
                            },
                            {
                              name: '天津市妇联',
                              children: []
                            },
                            {
                              name: '汇添富基金',
                              children: []
                            },
                            {
                              name: '财视网',
                              children: []
                            },
                            {
                              name: '齐发娱乐',
                              children: []
                            },
                            {
                              name: '华夏视点网',
                              children: []
                            },
                            {
                              name: '祖国网',
                              children: []
                            },
                            {
                              name: '汕头都市报',
                              children: []
                            },
                            {
                              name: '豫网',
                              children: []
                            },
                            {
                              name: '中国讲师网',
                              children: []
                            }
                          ]
                        },
                        {
                          name: '健康界',
                          children: []
                        }
                      ]
                    },
                    {
                      name: 'MBQQ表情',
                      children: [
                        {
                          name: '中国华南网',
                          children: []
                        },
                        {
                          name: '天长在线',
                          children: []
                        },
                        {
                          name: '万家热线',
                          children: []
                        },
                        {
                          name: '凌源生活网',
                          children: []
                        },
                        {
                          name: '齐鲁生活网',
                          children: []
                        },
                        {
                          name: '易天富基金网',
                          children: []
                        },
                        {
                          name: '南方网',
                          children: []
                        },
                        {
                          name: '项城网',
                          children: []
                        },
                        {
                          name: '大家车网',
                          children: []
                        },
                        {
                          name: '城市经济导报',
                          children: []
                        },
                        {
                          name: '大众经济网',
                          children: []
                        },
                        {
                          name: '股金所',
                          children: []
                        },
                        {
                          name: '创幻财经',
                          children: []
                        },
                        {
                          name: '南京热线',
                          children: []
                        },
                        {
                          name: '公务员考试网',
                          children: []
                        },
                        {
                          name: '投资中国',
                          children: []
                        },
                        {
                          name: '郴房有约',
                          children: []
                        },
                        {
                          name: '科技资讯网',
                          children: []
                        },
                        {
                          name: '成功保险网',
                          children: []
                        },
                        {
                          name: '核心竞争力',
                          children: []
                        },
                        {
                          name: '深圳信息网',
                          children: []
                        },
                        {
                          name: '财经观察网',
                          children: []
                        },
                        {
                          name: '行业研究网',
                          children: []
                        },
                        {
                          name: '新海外',
                          children: []
                        },
                        {
                          name: '企业观察网',
                          children: []
                        },
                        {
                          name: '荆州新闻网',
                          children: []
                        },
                        {
                          name: '中华写字楼网',
                          children: []
                        },
                        {
                          name: '资本观察网',
                          children: []
                        },
                        {
                          name: '长安街新闻',
                          children: []
                        },
                        {
                          name: '21商评网',
                          children: []
                        },
                        {
                          name: '北方网',
                          children: []
                        },
                        {
                          name: '亚汇网',
                          children: []
                        },
                        {
                          name: '华东在线',
                          children: []
                        },
                        {
                          name: '房多多',
                          children: []
                        },
                        {
                          name: '水木年华',
                          children: []
                        },
                        {
                          name: '天下银财富汇',
                          children: []
                        },
                        {
                          name: '黔东南信息港',
                          children: []
                        },
                        {
                          name: '新疆都市报',
                          children: []
                        },
                        {
                          name: '北京市青年党建研究会',
                          children: []
                        },
                        {
                          name: '东方时间网',
                          children: []
                        },
                        {
                          name: '科普新闻网',
                          children: []
                        },
                        {
                          name: '八桂网',
                          children: []
                        },
                        {
                          name: '金融网',
                          children: []
                        },
                        {
                          name: '留学网',
                          children: []
                        },
                        {
                          name: '信息时报',
                          children: []
                        },
                        {
                          name: '科研信息网',
                          children: []
                        },
                        {
                          name: '288job.com',
                          children: []
                        },
                        {
                          name: '期货日报网',
                          children: []
                        },
                        {
                          name: '神州头条',
                          children: []
                        },
                        {
                          name: '永城市机关党建网',
                          children: []
                        },
                        {
                          name: 'FX168财经网',
                          children: []
                        },
                        {
                          name: '艾瑞网',
                          children: []
                        },
                        {
                          name: '湖南好货网',
                          children: []
                        },
                        {
                          name: 'ASP站长网',
                          children: []
                        },
                        {
                          name: '陇南都市网',
                          children: []
                        },
                        {
                          name: '火星24小时',
                          children: []
                        },
                        {
                          name: '爱财经新闻网',
                          children: []
                        },
                        {
                          name: '汉诺威军事网',
                          children: []
                        },
                        {
                          name: '唐山站长网',
                          children: []
                        }
                      ]
                    },
                    {
                      name: '散户大家庭',
                      children: [
                        {
                          name: '中国金融网',
                          children: [
                            {
                              name: '楼事网',
                              children: [
                                {
                                  name: '海宁新闻网',
                                  children: [
                                    {
                                      name: '鹏鹏娱乐新闻网',
                                      children: []
                                    },
                                    {
                                      name: '嘉鱼网',
                                      children: []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              name: '河源站长网',
                              children: []
                            },
                            {
                              name: '武汉站长网',
                              children: []
                            },
                            {
                              name: '好传媒',
                              children: []
                            },
                            {
                              name: '汕尾站长网',
                              children: []
                            },
                            {
                              name: '华夏网',
                              children: []
                            },
                            {
                              name: '南京站长网',
                              children: []
                            },
                            {
                              name: '浙我家',
                              children: []
                            },
                            {
                              name: '开源中国社区',
                              children: []
                            },
                            {
                              name: '韶关站长网',
                              children: []
                            },
                            {
                              name: '网站开发网',
                              children: []
                            },
                            {
                              name: '鄂州第一网',
                              children: []
                            },
                            {
                              name: '芒果tv',
                              children: []
                            },
                            {
                              name: '泰安站长网',
                              children: []
                            },
                            {
                              name: '海西冷湖网',
                              children: []
                            },
                            {
                              name: '周口信息网',
                              children: []
                            },
                            {
                              name: '中国空军网',
                              children: []
                            },
                            {
                              name: '百事网',
                              children: []
                            },
                            {
                              name: '潍坊站长网',
                              children: []
                            },
                            {
                              name: '今日股市行情网',
                              children: []
                            },
                            {
                              name: '遛天津网',
                              children: []
                            },
                            {
                              name: '欲来风去',
                              children: []
                            },
                            {
                              name: '玉林站长网',
                              children: []
                            },
                            {
                              name: '淮北站长网',
                              children: []
                            },
                            {
                              name: '海南站长网',
                              children: []
                            },
                            {
                              name: '常德站长网',
                              children: []
                            },
                            {
                              name: '江苏门户网',
                              children: []
                            },
                            {
                              name: '江苏网',
                              children: []
                            },
                            {
                              name: '南充站长网',
                              children: []
                            },
                            {
                              name: '晋江门户网',
                              children: []
                            },
                            {
                              name: '衡阳站长网',
                              children: []
                            },
                            {
                              name: '沈阳站长网',
                              children: []
                            },
                            {
                              name: '应用开发计算网',
                              children: []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: '笑奇网',
                      children: [
                        {
                          name: '齐鲁壹点',
                          children: [
                            {
                              name: '海南特区报',
                              children: []
                            },
                            {
                              name: '河南梦之网网络科技有限公司',
                              children: []
                            },
                            {
                              name: '亚洲财经网',
                              children: []
                            },
                            {
                              name: '非常???普',
                              children: []
                            },
                            {
                              name: '今报网',
                              children: []
                            },
                            {
                              name: '央视网',
                              children: []
                            },
                            {
                              name: '乌有之乡网刊',
                              children: []
                            },
                            {
                              name: '人民交通网',
                              children: []
                            },
                            {
                              name: '服装网',
                              children: []
                            },
                            {
                              name: '黑马吧股票',
                              children: []
                            },
                            {
                              name: '古神树',
                              children: []
                            },
                            {
                              name: '很萝莉',
                              children: []
                            },
                            {
                              name: '中国质量新闻网',
                              children: []
                            },
                            {
                              name: '港股那点事',
                              children: []
                            },
                            {
                              name: '福建在线',
                              children: []
                            },
                            {
                              name: '西安新闻网',
                              children: []
                            },
                            {
                              name: '网贷点评',
                              children: []
                            },
                            {
                              name: '我车网',
                              children: []
                            },
                            {
                              name: '国企网',
                              children: []
                            },
                            {
                              name: '宣城新闻网',
                              children: []
                            },
                            {
                              name: '锌媒体',
                              children: []
                            },
                            {
                              name: '连线家',
                              children: []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: '热点资讯',
                      children: [
                        {
                          name: '中公金融人网',
                          children: []
                        }
                      ]
                    },
                    {
                      name: '侨报网',
                      children: []
                    },
                    {
                      name: '沂南网',
                      children: []
                    },
                    {
                      name: '南宁市房产资金管理中心',
                      children: []
                    },
                    {
                      name: '头条新闻网',
                      children: []
                    },
                    {
                      name: '深泽百科网',
                      children: []
                    },
                    {
                      name: '绥中新媒体',
                      children: []
                    },
                    {
                      name: '智通财经网',
                      children: []
                    },
                    {
                      name: '观点地产网',
                      children: []
                    },
                    {
                      name: '中国网',
                      children: []
                    },
                    {
                      name: '云掌财经',
                      children: []
                    },
                    {
                      name: '抽屉最新榜',
                      children: []
                    },
                    {
                      name: '聚够网',
                      children: []
                    },
                    {
                      name: '杭州网',
                      children: []
                    },
                    {
                      name: '手机新浪网',
                      children: []
                    },
                    {
                      name: '中金在线',
                      children: []
                    },
                    {
                      name: '绥棱新媒体',
                      children: []
                    },
                    {
                      name: '366新闻网',
                      children: []
                    },
                    {
                      name: '潮起网',
                      children: []
                    },
                    {
                      name: '深圳热线',
                      children: []
                    },
                    {
                      name: '益盟操盘手',
                      children: []
                    },
                    {
                      name: '沈阳市政府网络数据中心',
                      children: []
                    },
                    {
                      name: '诸葛找房',
                      children: []
                    },
                    {
                      name: '中国基金网',
                      children: []
                    },
                    {
                      name: '艾媒网',
                      children: []
                    },
                    {
                      name: '融资中国',
                      children: []
                    },
                    {
                      name: '奥一网',
                      children: []
                    },
                    {
                      name: '虎扑篮球',
                      children: []
                    },
                    {
                      name: '嘉兴人网',
                      children: []
                    },
                    {
                      name: '依安在线',
                      children: []
                    },
                    {
                      name: '北京联盟',
                      children: []
                    },
                    {
                      name: '来说历史故事和未解之谜',
                      children: []
                    },
                    {
                      name: '领航财经网',
                      children: []
                    },
                    {
                      name: '市场周刊杂志社官网',
                      children: []
                    },
                    {
                      name: '证券时报',
                      children: []
                    },
                    {
                      name: '中国财富网',
                      children: []
                    },
                    {
                      name: '财经钻',
                      children: []
                    },
                    {
                      name: '天涯易读',
                      children: []
                    },
                    {
                      name: '经济网',
                      children: []
                    },
                    {
                      name: '每日财经网',
                      children: []
                    },
                    {
                      name: 'A看点网',
                      children: []
                    },
                    {
                      name: '提交文章',
                      children: []
                    },
                    {
                      name: '时尚周刊',
                      children: []
                    },
                    {
                      name: '春城壹网',
                      children: []
                    },
                    {
                      name: '经济观察网',
                      children: []
                    },
                    {
                      name: '齐鲁财富网',
                      children: []
                    },
                    {
                      name: '全景财经',
                      children: []
                    },
                    {
                      name: '云南房网',
                      children: []
                    },
                    {
                      name: '施索恩工作室',
                      children: []
                    },
                    {
                      name: '中国地产金融网',
                      children: []
                    },
                    {
                      name: '拓曼贸易网',
                      children: []
                    },
                    {
                      name: '舜网',
                      children: []
                    },
                    {
                      name: '鄂东网',
                      children: []
                    },
                    {
                      name: '大庆商务网',
                      children: []
                    },
                    {
                      name: '精工网',
                      children: []
                    },
                    {
                      name: '泛储新闻网',
                      children: []
                    },
                    {
                      name: '她理财',
                      children: []
                    },
                    {
                      name: '每日娱乐网',
                      children: []
                    },
                    {
                      name: '芥末堆网',
                      children: []
                    },
                    {
                      name: '芥末堆',
                      children: []
                    },
                    {
                      name: '新易汽车',
                      children: []
                    },
                    {
                      name: '商控网',
                      children: []
                    },
                    {
                      name: '广物网',
                      children: []
                    },
                    {
                      name: 'IT商业新闻网',
                      children: []
                    },
                    {
                      name: '农安一二三网',
                      children: []
                    },
                    {
                      name: '阿狸观点网',
                      children: []
                    },
                    {
                      name: '鲁南快报',
                      children: []
                    },
                    {
                      name: '中国彩虹热线',
                      children: []
                    },
                    {
                      name: '汉川门户网',
                      children: []
                    },
                    {
                      name: '新闻360',
                      children: []
                    },
                    {
                      name: '山阴新闻网',
                      children: []
                    },
                    {
                      name: '马蹄新闻',
                      children: []
                    },
                    {
                      name: '链路财经',
                      children: []
                    },
                    {
                      name: '西坡在线',
                      children: []
                    },
                    {
                      name: '大章丘网',
                      children: []
                    },
                    {
                      name: '邯郸网',
                      children: []
                    },
                    {
                      name: '第一推信息网',
                      children: []
                    },
                    {
                      name: '财新网',
                      children: []
                    },
                    {
                      name: '张家界旅游卓越网',
                      children: []
                    },
                    {
                      name: '齐鲁网',
                      children: []
                    },
                    {
                      name: '平湖新闻',
                      children: []
                    },
                    {
                      name: '第一配资网',
                      children: []
                    },
                    {
                      name: '配资网查',
                      children: []
                    },
                    {
                      name: '网贷财经',
                      children: []
                    },
                    {
                      name: '一本财经网',
                      children: []
                    },
                    {
                      name: '国际商报广东记者站',
                      children: []
                    },
                    {
                      name: '股票之声',
                      children: []
                    },
                    {
                      name: '若怒网',
                      children: []
                    },
                    {
                      name: '中证网',
                      children: []
                    },
                    {
                      name: '西丰新闻网',
                      children: []
                    },
                    {
                      name: '36kr',
                      children: []
                    },
                    {
                      name: '永安白果',
                      children: []
                    },
                    {
                      name: 'www.hkxwzx.com',
                      children: []
                    },
                    {
                      name: '吉林今日新闻',
                      children: []
                    },
                    {
                      name: '广州商务网',
                      children: []
                    },
                    {
                      name: '厦门商务网',
                      children: []
                    },
                    {
                      name: '投中网',
                      children: []
                    },
                    {
                      name: '爱股票',
                      children: []
                    },
                    {
                      name: '中国商业观察网',
                      children: []
                    },
                    {
                      name: '映象网',
                      children: []
                    },
                    {
                      name: '大参考',
                      children: []
                    },
                    {
                      name: '记录者网',
                      children: []
                    },
                    {
                      name: '深圳论坛',
                      children: []
                    },
                    {
                      name: '??门市信息行业协会',
                      children: []
                    },
                    {
                      name: '财股网',
                      children: []
                    },
                    {
                      name: '南方财经网',
                      children: []
                    },
                    {
                      name: '吉发网',
                      children: []
                    },
                    {
                      name: '炫彩新闻',
                      children: []
                    },
                    {
                      name: '城市信报',
                      children: []
                    },
                    {
                      name: '邳州在线',
                      children: []
                    },
                    {
                      name: '东莞网',
                      children: []
                    },
                    {
                      name: '云南信息报数字报',
                      children: []
                    },
                    {
                      name: '珠海博爱妇产医院',
                      children: []
                    },
                    {
                      name: '偃师市首阳山镇龙丰特种动物养殖场',
                      children: []
                    },
                    {
                      name: '铁岭360',
                      children: []
                    },
                    {
                      name: '汉丰网',
                      children: []
                    },
                    {
                      name: 'YY贸易网',
                      children: []
                    }
                  ]
                },
                {
                  name: '贵阳人民广播电台',
                  children: [
                    {
                      name: '真人网',
                      children: []
                    }
                  ]
                },
                {
                  name: '中国中小企业河南网',
                  children: []
                },
                {
                  name: '中国特产网',
                  children: []
                },
                {
                  name: '四月网',
                  children: []
                }
              ]
            },
            {
              name: '引力资讯',
              children: []
            },
            {
              name: '爱妮微',
              children: []
            }
          ]
        },
        web_spread_content: [
          {
            keyword: '新城',
            total: 10885
          },
          {
            keyword: '控股',
            total: 9439
          },
          {
            keyword: '王振华',
            total: 9064
          },
          {
            keyword: '女童',
            total: 4553
          },
          {
            keyword: '董事长',
            total: 3839
          },
          {
            keyword: '晓松',
            total: 3777
          },
          {
            keyword: '周某某',
            total: 2139
          },
          {
            keyword: '王某某',
            total: 2006
          },
          {
            keyword: '普陀',
            total: 1615
          },
          {
            keyword: '上市公司',
            total: 1560
          },
          {
            keyword: '王某',
            total: 1558
          },
          {
            keyword: '周某',
            total: 1546
          },
          {
            keyword: '董事会',
            total: 1476
          },
          {
            keyword: '强制措施',
            total: 1350
          },
          {
            keyword: '跌停',
            total: 1284
          },
          {
            keyword: '警方',
            total: 1149
          },
          {
            keyword: '儿童',
            total: 1106
          },
          {
            keyword: '董事',
            total: 1055
          },
          {
            keyword: '港股',
            total: 913
          },
          {
            keyword: '刑事拘留',
            total: 896
          },
          {
            keyword: '质押',
            total: 868
          },
          {
            keyword: '股价',
            total: 848
          },
          {
            keyword: '市值',
            total: 804
          },
          {
            keyword: '内幕',
            total: 748
          },
          {
            keyword: '常州',
            total: 747
          },
          {
            keyword: '女孩',
            total: 739
          },
          {
            keyword: '刑拘',
            total: 665
          },
          {
            keyword: '上海市公安局',
            total: 658
          },
          {
            keyword: '未成年人',
            total: 646
          },
          {
            keyword: '犯罪嫌疑人',
            total: 599
          },
          {
            keyword: '江苏人',
            total: 560
          },
          {
            keyword: '幼女',
            total: 558
          },
          {
            keyword: '总裁',
            total: 535
          },
          {
            keyword: '江苏省人大',
            total: 514
          },
          {
            keyword: '撕裂伤',
            total: 493
          },
          {
            keyword: '母亲',
            total: 493
          },
          {
            keyword: '德润',
            total: 485
          },
          {
            keyword: '常州市',
            total: 468
          },
          {
            keyword: '公安机关',
            total: 462
          },
          {
            keyword: '范凯',
            total: 437
          },
          {
            keyword: '黑天鹅',
            total: 436
          },
          {
            keyword: '武进',
            total: 434
          },
          {
            keyword: '控股集团',
            total: 428
          },
          {
            keyword: '严义明',
            total: 410
          },
          {
            keyword: '基金',
            total: 393
          },
          {
            keyword: '评级',
            total: 391
          },
          {
            keyword: '七色光',
            total: 372
          },
          {
            keyword: '江苏',
            total: 372
          },
          {
            keyword: '律师',
            total: 368
          },
          {
            keyword: '武进区',
            total: 367
          }
        ],
        web_spread_emotion: [
          {
            keyword: '猥亵',
            total: 8882,
            emotion: 'negative'
          },
          {
            keyword: '涉嫌',
            total: 1213,
            emotion: 'negative'
          },
          {
            keyword: '内幕',
            total: 833,
            emotion: 'negative'
          },
          {
            keyword: '强奸',
            total: 621,
            emotion: 'negative'
          },
          {
            keyword: '暴跌',
            total: 571,
            emotion: 'negative'
          },
          {
            keyword: '受害',
            total: 488,
            emotion: 'negative'
          },
          {
            keyword: '犯罪',
            total: 408,
            emotion: 'negative'
          },
          {
            keyword: '负债',
            total: 408,
            emotion: 'negative'
          },
          {
            keyword: '从重',
            total: 296,
            emotion: 'negative'
          },
          {
            keyword: '哭诉',
            total: 276,
            emotion: 'negative'
          },
          {
            keyword: '不安',
            total: 249,
            emotion: 'negative'
          },
          {
            keyword: '侵害',
            total: 232,
            emotion: 'negative'
          },
          {
            keyword: '受害者',
            total: 232,
            emotion: 'negative'
          },
          {
            keyword: '罪名',
            total: 183,
            emotion: 'negative'
          },
          {
            keyword: '下跌',
            total: 181,
            emotion: 'negative'
          },
          {
            keyword: '高级',
            total: 166,
            emotion: 'positive'
          },
          {
            keyword: '道德',
            total: 137,
            emotion: 'positive'
          },
          {
            keyword: '慈善',
            total: 123,
            emotion: 'positive'
          },
          {
            keyword: '上游',
            total: 118,
            emotion: 'positive'
          },
          {
            keyword: '五星级',
            total: 116,
            emotion: 'positive'
          },
          {
            keyword: '富国',
            total: 103,
            emotion: 'positive'
          },
          {
            keyword: '幸福',
            total: 97,
            emotion: 'positive'
          },
          {
            keyword: '奇妙',
            total: 78,
            emotion: 'positive'
          },
          {
            keyword: '精选',
            total: 78,
            emotion: 'positive'
          },
          {
            keyword: '公允',
            total: 77,
            emotion: 'positive'
          },
          {
            keyword: '正义',
            total: 69,
            emotion: 'positive'
          },
          {
            keyword: '光彩',
            total: 68,
            emotion: 'positive'
          },
          {
            keyword: '澎湃',
            total: 64,
            emotion: 'positive'
          },
          {
            keyword: '乐园',
            total: 49,
            emotion: 'positive'
          },
          {
            keyword: '保护',
            total: 46,
            emotion: 'positive'
          }
        ],
        web_spread_content_trend: {
          keys: [
            '2019-07-03',
            '2019-07-04',
            '2019-07-05',
            '2019-07-06',
            '2019-07-07',
            '2019-07-08',
            '2019-07-09'
          ],
          data: [
            [
              {
                media: '女童',
                count: 65985
              },
              {
                media: '新城',
                count: 64800
              },
              {
                media: '董事长',
                count: 62035
              },
              {
                media: '控股',
                count: 61664
              },
              {
                media: '王某',
                count: 34527
              },
              {
                media: '撕裂伤',
                count: 22099
              },
              {
                media: '新民晚报',
                count: 20752
              },
              {
                media: '王振华',
                count: 20605
              },
              {
                media: '强制措施',
                count: 19303
              },
              {
                media: '上海',
                count: 19329
              },
              {
                media: '周某',
                count: 15930
              },
              {
                media: '犯罪行为',
                count: 13263
              },
              {
                media: '母亲',
                count: 12106
              },
              {
                media: '上市公司',
                count: 10330
              },
              {
                media: '阴道',
                count: 8934
              },
              {
                media: '验伤',
                count: 6755
              },
              {
                media: '有期徒刑',
                count: 4597
              },
              {
                media: '女儿',
                count: 4158
              },
              {
                media: '酒店',
                count: 3709
              },
              {
                media: '幼女',
                count: 2783
              }
            ],
            [
              {
                media: '新城',
                count: 51453
              },
              {
                media: '女童',
                count: 49473
              },
              {
                media: '控股',
                count: 48609
              },
              {
                media: '董事长',
                count: 40299
              },
              {
                media: '王振华',
                count: 16957
              },
              {
                media: '撕裂伤',
                count: 10454
              },
              {
                media: '王某',
                count: 8324
              },
              {
                media: '上海',
                count: 6326
              },
              {
                media: '新民晚报',
                count: 5906
              },
              {
                media: '凤凰网',
                count: 5753
              },
              {
                media: '周某',
                count: 5242
              },
              {
                media: '阴道',
                count: 5120
              },
              {
                media: '法院',
                count: 4389
              },
              {
                media: '王某某',
                count: 4365
              },
              {
                media: '周某某',
                count: 4280
              },
              {
                media: '儿童',
                count: 3890
              },
              {
                media: '强制措施',
                count: 3961
              },
              {
                media: '公安',
                count: 3453
              },
              {
                media: '犯罪行为',
                count: 3139
              },
              {
                media: '幼童',
                count: 2122
              }
            ],
            [
              {
                media: '新城',
                count: 15588
              },
              {
                media: '控股',
                count: 13813
              },
              {
                media: '女童',
                count: 13529
              },
              {
                media: '董事长',
                count: 9781
              },
              {
                media: '王振华',
                count: 6236
              },
              {
                media: '儿童',
                count: 2750
              },
              {
                media: '王某某',
                count: 2646
              },
              {
                media: '幼女',
                count: 2009
              },
              {
                media: '底线',
                count: 1744
              },
              {
                media: '周某某',
                count: 1394
              },
              {
                media: '动画',
                count: 1206
              },
              {
                media: '王某',
                count: 1132
              },
              {
                media: '撕裂伤',
                count: 1126
              },
              {
                media: '态度',
                count: 1125
              },
              {
                media: '保护',
                count: 1026
              },
              {
                media: '监狱',
                count: 960
              },
              {
                media: '歧视',
                count: 935
              },
              {
                media: '人人喊打',
                count: 923
              },
              {
                media: '底端',
                count: 920
              },
              {
                media: '最底层',
                count: 900
              }
            ],
            [
              {
                media: '儿童',
                count: 50566
              },
              {
                media: '儿童性',
                count: 49961
              },
              {
                media: '个例',
                count: 49874
              },
              {
                media: '冰山一角',
                count: 49359
              },
              {
                media: '零容忍',
                count: 48507
              },
              {
                media: '新城',
                count: 41482
              },
              {
                media: '控股',
                count: 35412
              },
              {
                media: '女童',
                count: 34502
              },
              {
                media: '刑拘',
                count: 32725
              },
              {
                media: '小甜饼',
                count: 6559
              },
              {
                media: '镣铐',
                count: 4948
              },
              {
                media: '董事长',
                count: 3604
              },
              {
                media: '王某某',
                count: 2343
              },
              {
                media: '歧视',
                count: 1977
              },
              {
                media: '监狱',
                count: 1937
              },
              {
                media: '幼女',
                count: 1896
              },
              {
                media: '人人喊打',
                count: 1876
              },
              {
                media: '王振华',
                count: 1802
              },
              {
                media: '保护',
                count: 1782
              },
              {
                media: '父母',
                count: 1413
              }
            ],
            [
              {
                media: '新城',
                count: 12364
              },
              {
                media: '儿童',
                count: 11791
              },
              {
                media: '儿童性',
                count: 11432
              },
              {
                media: '个例',
                count: 11371
              },
              {
                media: '冰山一角',
                count: 11205
              },
              {
                media: '控股',
                count: 11169
              },
              {
                media: '零容忍',
                count: 11041
              },
              {
                media: '女童',
                count: 10092
              },
              {
                media: '刑拘',
                count: 8640
              },
              {
                media: '董事长',
                count: 2085
              },
              {
                media: '王振华',
                count: 1740
              },
              {
                media: '小甜饼',
                count: 1027
              },
              {
                media: '保护',
                count: 851
              },
              {
                media: '伤害',
                count: 594
              },
              {
                media: '周某',
                count: 580
              },
              {
                media: '小天使',
                count: 587
              },
              {
                media: '美国防',
                count: 581
              },
              {
                media: '王某某',
                count: 448
              },
              {
                media: '镣铐',
                count: 308
              },
              {
                media: '有期徒刑',
                count: 305
              }
            ],
            [
              {
                media: '新城',
                count: 5021
              },
              {
                media: '控股',
                count: 4371
              },
              {
                media: '女童',
                count: 3200
              },
              {
                media: '王振华',
                count: 2369
              },
              {
                media: '儿童',
                count: 2022
              },
              {
                media: '董事长',
                count: 1824
              },
              {
                media: '儿童性',
                count: 1810
              },
              {
                media: '个例',
                count: 1589
              },
              {
                media: '冰山一角',
                count: 1581
              },
              {
                media: '零容忍',
                count: 1555
              },
              {
                media: '教育',
                count: 715
              },
              {
                media: '保护',
                count: 750
              },
              {
                media: '动画',
                count: 709
              },
              {
                media: '伤害',
                count: 700
              },
              {
                media: '美国防',
                count: 691
              },
              {
                media: '小天使',
                count: 690
              },
              {
                media: '王某某',
                count: 458
              },
              {
                media: '周某某',
                count: 423
              },
              {
                media: '周某',
                count: 438
              },
              {
                media: '上市公司',
                count: 312
              }
            ],
            [
              {
                media: '新城',
                count: 3540
              },
              {
                media: '控股',
                count: 3327
              },
              {
                media: '王振华',
                count: 2303
              },
              {
                media: '女童',
                count: 1695
              },
              {
                media: '董事长',
                count: 1431
              },
              {
                media: '晓松',
                count: 1103
              },
              {
                media: '儿童',
                count: 827
              },
              {
                media: '刑拘',
                count: 819
              },
              {
                media: '儿童性',
                count: 697
              },
              {
                media: '董事会',
                count: 606
              },
              {
                media: '个例',
                count: 589
              },
              {
                media: '冰山一角',
                count: 586
              },
              {
                media: '零容忍',
                count: 582
              },
              {
                media: '内幕',
                count: 551
              },
              {
                media: '质押',
                count: 421
              },
              {
                media: '跌停',
                count: 405
              },
              {
                media: '知情人',
                count: 312
              },
              {
                media: '交易',
                count: 235
              },
              {
                media: '小天使',
                count: 178
              },
              {
                media: '伤害',
                count: 185
              }
            ]
          ],
          desc: ['首次爆发', '达到顶峰', '开始衰退', '进入潜伏', '二次爆发', '到达顶峰', '结束时间']
        },
        web_event_spread_path: [
          {
            keyword: '',
            text:
              '人民日报、光明日报、央视网、新京报、澎湃新闻、新民晚报、每日经济新闻、经济观察网、界面新闻等众多权威媒体聚焦本次新城控股董事长猥亵女童事件，相继发布了新闻报道。之后新浪网、搜狐网、腾讯网、凤凰网、网易等全国性商业门户网站及一点资讯、今日头条、百家号等站点大量跟进参与发布各来源的相关报道。从整体的发布趋势来看，2019-07-04 ZAKER发布了最多的信息。而不同时间点主要的传播媒体并不一致，呈现出多主体参与、非中心化的传播特征。从传播趋势图来看，可以发现《新民晚报》的报道是事件爆发的第一个节点，ZAKER的文章被多个媒体转载，是后续发酵的重要阵地。'
          },
          {
            keyword: '传播主体',
            text: ['媒体主导型']
          }
        ],
        web_spread_content_analyse:
          '从整体事件相关信息来看，主要的关键字包含：新城、控股、王振华、女童、董事长、晓松、周某某、王某某、普陀、上市公司、猥亵、涉嫌、内幕、强奸、暴跌、受害、犯罪、负债。由此可以看出整体事件的概况。其中核心内容为：王振华猥亵女童，新城控股港股暴跌，警方官方通报确认，新城控股变更董事长等。同时引发舆论围绕消息是否属实、是强奸还是猥亵、港股暴跌引发联动效应、信息披露是否合规等方面进行讨论，催生出多个媒体持续热议的舆论内容。从时间趋势来看，可以看到在7月3日晚间关键词走势出现持续变化，代表舆论重点的转变。',
        web_summary: [
          {
            text: '',
            summary:
              '本方案于2019-07-01至2019-07-13期间，采集了关于“新城控股董事长猥亵女童”事件的14401条相关信息。'
          },
          {
            text: '事件覆盖网媒数量',
            summary: '1456个'
          }
        ],
        web_source: [
          '事件起源自《新民晚报》于2019-07-03 14:56:00的报导，标题为: 上市公司新城控股董事长王某因猥亵9岁女童在沪被采取强制措施',
          '《新民晚报》披露此事后引起轩然大波，立刻有大量媒体跟进传播。'
        ],
        web_trend: []
      },
      loading: true,
      title: '',
      created: ''
    }
  },
  mounted() {
    this.loadData()
    this.$nextTick(() => {
      let _this = this
      window.onscroll = function() {
        let height = document.documentElement.scrollTop + 75
        if (height > 200) {
          $('#network-tab').addClass('scroll-tab')
          $('#network-tab').addClass('scroll')
        } else {
          $('#network-tab').removeClass('scroll')
          $('#network-tab').removeClass('scroll-tab')
        }
        let b = window.document.getElementById('qyfx').offsetTop
        let c = window.document.getElementById('zsfx').offsetTop
        let d = window.document.getElementById('tjfx').offsetTop
        let e = window.document.getElementById('nrfx').offsetTop - 300
        if (height < b) {
          _this.switchTab(0)
        } else if (height >= b && height < c) {
          _this.switchTab(1)
        } else if (height >= c && height < d) {
          _this.switchTab(2)
        } else if (height >= d && height < e) {
          _this.switchTab(3)
        } else {
          _this.switchTab(4)
        }
      }
    })
  },
  methods: {
    isArray(data) {
      return _.isArray(data)
    },
    switchTab(index, scrollTo = false) {
      if (scrollTo) {
        var h = 0
        switch (index) {
          case 0:
            document.documentElement.scrollTop = h
            break
          case 1:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('qyfx').offsetTop - h
            break
          case 2:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('zsfx').offsetTop - h
            break
          case 3:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('tjfx').offsetTop - h
            break
          case 4:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('nrfx').offsetTop - h
            break
        }
      }
      $('.flex-container a').removeClass('selected')
      $('.flex-container a:eq(' + index + ')').addClass('selected')
    },
    loadData() {
      this.title = '新城控股'
      this.created = '2019-08-07 16:08:08'
      this.loading = false
      this.$nextTick(() => {
        this.createChart()
      })
    },
    createChart() {
      this.trendChart('web_number_trend')
      this.loopChart('web_type')
      this.pointChart('web_key_media_trend')
      this.pointChart('web_spread_content_trend')
      this.treeChart('web_spread_path')
      this.wordCloudChart('web_spread_content')
      this.wordPopChart('web_spread_emotion')
      if (this.data['web_event_trend'].isIncubation) {
        this.eventTrend('media-event-trend') // 该分析为事件时调用
      }
      let elements = window.document.getElementsByClassName('mini-trend')
      for (let i = 0; i < elements.length; i++) {
        this.miniTrendChart(i, elements[i])
      }
    },
    miniTrendChart(index, element) {
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          show: false
        },
        legend: {
          top: '5px',
          icon: 'circle',
          itemGap: 20,
          itemWidth: 10,
          itemHeight: 10,
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '70px',
          bottom: '20px',
          width: '90%',
          containLabel: true,
          show: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          show: false
        },
        yAxis: { type: 'value', show: false },
        color: [
          '#83d944',
          '#f0a142 ',
          '#3178d2 ',
          '#568efc ',
          '#8abaf8',
          '#67a5ff',
          '#11abf3',
          '#fbe44d',
          '#55d9ff',
          '#74ccb1',
          '#ee8131'
        ],
        series: []
      }
      let chartData = this.data['web_main_platform'][index].trend
      option.xAxis.data = chartData.keys
      option.series = [
        {
          name: '走势简图',
          type: 'line',
          color: '#0099f0',
          smooth: true,
          symbolSize: 0,
          areaStyle: { normal: {} },
          data: chartData.data
        }
      ]
      let chart = globalCreateChart(element, option)
    },
    trendChart(id) {
      let chartData = this.data[id]
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          top: '5px',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '70px',
          bottom: '20px',
          width: '90%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: { type: 'value' },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          }
        ],
        color: [
          '#83d944',
          '#f0a142 ',
          '#3178d2 ',
          '#568efc ',
          '#8abaf8',
          '#67a5ff',
          '#11abf3',
          '#fbe44d',
          '#55d9ff',
          '#74ccb1',
          '#ee8131'
        ],
        series: []
      }
      let seriesData = []
      _(chartData.data).forEach((item, index) => {
        seriesData.push({
          name: chartData.type[index],
          type: 'line',
          smooth: true,
          areaStyle: { normal: {} },
          data: item
        })
      })
      option.xAxis.data = chartData.keys
      option.series = seriesData
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    loopChart(id) {
      let chartData = this.data[id]
      let total = parseInt(chartData.gov) + parseInt(chartData.general)
      let rateMap = [
        ((chartData.gov / total) * 100).toFixed(2),
        ((chartData.general / total) * 100).toFixed(2)
      ]
      let legend = ['政府网站', '一般媒体']
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: '10px',
          bottom: '20px',
          data: legend,
          itemWidth: 10,
          itemHeight: 10,
          icon: 'circle',
          itemGap: 5,
          formatter: function(name) {
            let index = legend.indexOf(name)
            return name + ' ' + rateMap[index] + '%'
          }
        },
        color: ['#fd5066', '#009df6'],
        series: [
          {
            name: '媒体信息来源类型分布',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { name: '政府网站', value: chartData.gov },
              { name: '一般媒体', value: chartData.general }
            ]
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    pointChart(id) {
      let chartData = this.data[id]
      let seriesData = []
      let xAxis = []
      var maxTotal = 0
      chartData.data.map((pdata, index) => {
        pdata.map((sdata, sindex) => {
          if (sdata.count > maxTotal) maxTotal = sdata.count
        })
      })
      chartData.keys = chartData.keys.map((item, index) => {
        return { value: [item, chartData.desc[index] ? chartData.desc[index] : ''] }
      })
      switch (id) {
        case 'web_key_media_trend':
          _(chartData.data).forEach((item, index) => {
            _(item).forEach((row, sub_index) => {
              if (sub_index <= 9) {
                seriesData.push({
                  name: '发布数量',
                  type: 'heatmap',
                  data: [[sub_index, index, row.count]],
                  label: {
                    normal: {
                      show: true,
                      formatter: row.media.substr(0, 6)
                    }
                  }
                })
              }
            })
          })
          break
        case 'web_spread_content_trend':
          _(chartData.data).forEach((item, index) => {
            _(item).forEach((row, sub_index) => {
              seriesData.push({
                name: row.keyword,
                type: 'heatmap',
                data: [[sub_index, index, row.count]],
                label: {
                  normal: {
                    show: true,
                    formatter: row.media
                  }
                }
              })
            })
          })
          break
      }
      let option = {
        tooltip: {
          position: 'top',
          formatter: function(params) {
            if (id == 'web_key_media_trend') {
              return (
                params.seriesName +
                '<br>' +
                chartData.data[params.data[1]][params.data[0]].media +
                '：' +
                params.data[2]
              )
            } else {
              return params.seriesName + '：' + params.data[2]
            }
          }
        },
        animation: false,
        grid: {
          bottom: 30,
          left: 130
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          splitArea: {
            show: true
          },
          show: true
        },
        yAxis: {
          type: 'category',
          data: chartData.keys,
          splitArea: {
            show: true
          },
          axisLabel: {
            formatter: function(arg) {
              return '{a|' + arg[0] + '} {b|' + arg[1] + '}'
            },
            rich: {
              b: {
                backgroundColor: '#e3eefd',
                padding: 3
              }
            }
          }
        },
        visualMap: {
          min: 0,
          max: maxTotal,
          calculable: true,
          orient: 'horizontal',
          show: false,
          inRange: {
            color: ['#ffbeb2', '#EE000F']
          }
        },
        series: seriesData
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    treeChart(id) {
      let chartData = this.data[id]
      //        chartData.children = chartData.children.map((children, index) => {
      //          children.collapsed = true
      //          return children
      //        })
      let option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            orient: 'horizontal', // vertical horizontal
            rootLocation: { x: 100, y: 230 },
            roam: true,
            data: [chartData],
            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',
            symbolSize: 7,
            label: {
              normal: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 9
              }
            },
            leaves: {
              label: {
                normal: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left'
                }
              }
            }
          }
        ]
      }
      var echarts = globalGetEcharts()
      let element = window.document.getElementById(id)
      let chart = echarts.init(element)
      chart.setOption(option)
    },
    wordCloudChart(id) {
      let chartData = this.data[id]
      let option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '信息数',
            type: 'wordCloud',
            shape: 'circle',
            top: '10%',
            width: '100%',
            height: '85%',
            right: null,
            bottom: null,
            sizeRange: [12, 30],
            rotationRange: [-90, 90],
            rotationStep: 45,
            gridSize: 8,
            drawOutOfBound: false,
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                //fontWeight: 'bold',
                color: function(params) {
                  // Random color
                  let color = ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e']
                  let index = Math.floor(Math.random() * color.length)
                  return color[index]
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            data: []
          }
        ]
      }
      let seriesData = []
      _(chartData).forEach((item, index) => {
        seriesData.push({ name: item.keyword, value: item.total })
      })
      option.series[0].data = seriesData
      let element = window.document.getElementById(id)
      globalCreateChart(element, option)
    },
    wordPopChart(id) {
      let chartData = this.data[id]
      let maxr =
        chartData.length >= 1
          ? chartData.sort((a, b) => {
              return b.total - a.total
            })[0].total
          : 0
      function Bubble(option) {
        var _defaultOption = {
          width: 300,
          height: 300,
          padding: 1.5,
          data: '',
          conEle: ''
        }
        option = $.extend(true, _defaultOption, option)
        this.width = option.width
        this.height = option.height
        this.padding = option.padding
        this.data = option.data //数据url或对象,必填
        this.conEle = option.conEle //svg容器(node或者选择器)，必填
        this.mouseenter = function(d, node) {}
        this.mouseleave = function(d, node) {}
        this.click = function(d) {}
      }
      Bubble.prototype.init = function() {
        var that = this,
          //1.设置颜色
          color = d3.scale.category20c(),
          //2.布局
          bubble = d3.layout
            .pack()
            .sort(null)
            .size([that.width, that.height])
            .radius(d => {
              let val = (d / maxr) * 50
              return val < 20 ? 20 : val
            })
            .padding(that.padding),
          //3.添加svg元素
          svg = d3
            .select(that.conEle)
            .append('svg')
            .attr('width', that.width)
            .attr('font-size', '12')
            .attr('height', that.height)
        //4.数据请求及图形绘制
        if (typeStr(that.data) == '[object string]') {
          d3.json(that.data, function(error, data) {
            if (error) throw error
            //1.对数据进行处理
            data = dataHandle(data)
            render(svg, bubble, that, data)
          })
        } else {
          render(svg, bubble, that, dataHandle(that.data))
        }
      }
      function typeStr(obj) {
        return Object.prototype.toString.call(obj).toLowerCase()
      }
      //Returns a flattened hierarchy containing all leaf nodes under the root.
      function classes(root) {
        var classes = [] //存储结果的数组
        /*
         * 自定义递归函数
         * 第二个参数指传入的json对象
         */
        function recurse(name, node) {
          if (node.children) {
            //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            node.children.forEach(function(child) {
              //将孩子结点中的每条数据
              recurse(node.name, child)
            })
          } else {
            //如果自身是孩子结点的，将内容压入数组
            classes.push({ name: node.name, value: node.size, props: node.props })
          }
        }
        recurse(null, root)
        return { children: classes }
      }
      function render(view, layout, context, data, cb) {
        var node = view
            .selectAll('.node')
            //绑定数据（配置结点）
            .data(
              layout.nodes(classes(data)).filter(function(d) {
                //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                return !d.children
              })
            )
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', function(d) {
              //设定g移动
              return 'translate(' + d.x + ',' + d.y + ')'
            }),
          usingNodes = node.filter(function(d) {
            return d.props.using
          }),
          time = +new Date(),
          duration = 1000,
          strokeWidth = 0
        node
          .append('circle')
          .attr('r', function(d) {
            //设置圆的半径
            return d.r
          })
          .style('fill', function(d) {
            //气泡颜色
            return d.props.color
          })
          .style('fill-opacity', 1)
        node
          .append('text')
          .attr('dy', '.3em')
          //设置文本对齐
          .style('text-anchor', 'middle')
          .style('font-size', '12px')
          .style('fill', function(d) {
            //字体颜色
            return '#fff'
          })
          //根据半径的大小来截取对应长度字符串(很重要)
          .text(function(d) {
            return d.name.substring(0, d.r / 8)
          })
        function animate() {
          var nowTime = +new Date()
          if (nowTime - duration > time) {
            time = nowTime
            strokeWidth = 0
          }
          strokeWidth += 0.6
          //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
          usingNodes
            .select('circle')
            .style('stroke-width', strokeWidth + 'px')
            .style('stroke-opacity', '0.3')
            .style('stroke', function(d) {
              return d.props.color
            })
          requestAnimationFrame(animate)
        }
        animate()
        node.on('mouseenter', function(d) {
          var node = this
          context.mouseenter(d, node)
        })
        node.on('mouseleave', function(d) {
          var node = this
          context.mouseleave(d, node)
        })
        node.on('click', function(d) {
          var node = this
          context.click(d)
        })
      }
      //定义数据处理方法
      function dataHandle(data) {
        var result = {
          name: 'flare',
          children: []
        }
        data.forEach(function(ele) {
          result.children.push({
            name: ele.name,
            size: ele.value,
            props: ele.props
          })
        })
        return result
      }
      function createInfoTip(d) {
        var html = '<div class="node-info fz-12"><ul>'
        html += '<li class="info-title"><span>' + d.name + '</span></li>'
        if (d.props.color == '#66a5ff') {
          html +=
            '<li class="info-content"><i class="bg-normal"></i><span class="info-content-label">信息数' +
            '</span><span class="info-content-text">' +
            d.value +
            '</span></li>'
        } else {
          html +=
            '<li class="info-content"><i class="bg-abnormal"></i><span class="info-content-label">信息数' +
            '</span><span class="info-content-text">' +
            d.value +
            '</span></li>'
        }
        html += '</ul></div>'
        return html
      }
      // 清除旧数据
      window
        .jQuery('#' + id)
        .children()
        .remove()
      d3.select('#' + id)
        .selectAll('*')
        .remove()
      var data = []
      _(chartData).forEach((item, i) => {
        data[data.length] = {
          name: item.keyword,
          value: item.total,
          props: {
            abnormal: false,
            color: item.emotion == 'positive' ? '#66a5ff' : '#fa3851',
            using: false
          }
        }
      })
      let element = window.document.getElementById(id)
      if (!element) return false // 切换时直接break
      var option = {
        data: data,
        conEle: '#' + id,
        width: 530,
        height: 290,
        padding: 2
      }
      var bubble = new Bubble(option)
      bubble.click = function(d) {
        // TODO:: 列表点击事件
      }
      bubble.mouseenter = function(d, node) {
        var $con = $('#' + id)
        var rectBox = $con[0].getBoundingClientRect()
        d3.select(node).style('cursor', 'pointer')
        $con.append(createInfoTip(d))
        $('.node-info')
          .css({
            left: d3.event.x + 20 - rectBox.left,
            top: d3.event.y + 20 - rectBox.top
          })
          .show()
      }
      bubble.mouseleave = function(d) {
        $('.node-info').remove()
      }
      bubble.init()
    },
    eventTrend(id) {
      let data = this.data['web_event_trend'].data.trend
      let keys = []
      let seriesData = []
      data.map(item => {
        keys.push(item.date)
        if (item.points) {
          seriesData.push({
            value: item.count,
            symbol: 'image://' + findPointImg(item.points[0]),
            name: item.points.join(',')
          })
        } else {
          seriesData.push({
            value: item.count,
            name: ''
          })
        }
      })
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: keys
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'line',
            stack: '总量',
            symbol: 'none',
            symbolSize: 20,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function(params) {
                  return params.data.name
                }
              }
            },
            data: seriesData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    findPointImg(name) {
      return findPointImg(name)
    }
  }
}
</script>
<style scoped>
.fxbg.pt_0 {
  padding-top: 0;
}
.h-290 {
  height: 290px;
}
.scroll-tab {
  position: fixed;
  z-index: 3000;
  left: auto;
  right: auto;
  top: 200px;
  margin: auto;
}
.scroll {
  top: 0px;
}
.scroll .fxbg {
  box-shadow: 0px 3px 13px 0px rgba(194, 194, 194, 0.2);
  padding: 10px 0;
}
.flex-container {
  justify-content: space-between;
  align-content: space-between;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 1160px;
  float: none;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 50px;
  border: none;
  font-size: 14px;
}
.btn_tab a.selected {
  color: #fff;
  background: rgba(42, 103, 179, 1);
}
.echarts_empty {
  height: 280px;
}
/*时间轴*/
.demo {
  width: 1080px;
  margin: 20px auto;
  padding: 45px 0 0;
}
.main-timeline {
  position: relative;
}
.main-timeline:before,
.main-timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline:before {
  content: '';
  width: 3px;
  height: calc(100% - 50px);
  background: #bebebe;
  position: absolute;
  top: 0;
  left: 50%;
}
.main-timeline .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 7px;
}
.main-timeline .circle {
  width: 450px;
  height: 70px;
  background: rgb(239, 244, 254);
  border-radius: 8px;
  float: left;
  position: relative;
  color: #000;
  margin-top: -35px;
}
.main-timeline .circle .title,
.main-timeline .circle .name {
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  width: 100%;
  text-align: left;
  font-size: 12px;
}
.main-timeline .content {
  display: table;
  padding: 0 5px;
  position: relative;
}
.main-timeline .year {
  display: block;
  margin: 0 0 50px 0;
  border-radius: 7px;
  font-size: 12px;
  color: #fff;
  width: 77px;
  height: 40px;
  background-color: #2a67b3;
  border-radius: 4.5px;
  margin-top: -38px;
  line-height: 15px;
  padding: 5px 3px;
  text-align: left;
}
.main-timeline .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 0;
  right: -12px;
  display: inline-block;
}
.main-timeline .timeline:first-child .timeline-content:before {
  top: 7px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle {
  float: right;
}
.main-timeline .timeline:nth-child(even) {
  margin-top: 60px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before {
  top: 3px;
}
.main-timeline .timeline:nth-child(even) .year {
  margin-top: -42px;
}
.main-timeline .timeline:nth-child(even) .icon {
  left: -10px;
  right: 0;
}
.main-timeline .mod-list {
  padding: 8px 10px;
}
/*主要发布渠道*/
.zyfbqd h3 {
  font-weight: 600;
}
.zyfbqd .mod-list-title2,
.zyfbqd .bt {
  padding-right: 10px;
  float: left;
}
.zyfbqd .mod-list-title2 {
  width: 140px;
}
.zyfbqd .bt {
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.zyfbqd .tag {
  display: inline-block;
  height: 36px;
}
.zyfbqd .xxzs {
  width: 56px;
  text-align: center;
}
/*事件传播内容*/
.sjcbnr .mod-list {
  margin-top: 10px;
  width: 130px;
}
.sjcbnr .mod-list li {
  height: 28px;
  line-height: 28px;
}
.sjcbnr .mod-list .num {
  margin-top: 6px;
}
.sjcbnr .mod-list a {
  width: 65px;
}
.h-355 {
  height: 355px;
}
/*事件关键时间点*/
.demo2 {
  width: 1120px;
  margin: 30px auto 0;
  padding: 0;
  position: relative;
}
.demo2:before {
  content: '';
  width: 3px;
  background: #bebebe;
  position: absolute;
  top: 40px;
  left: 10%;
  bottom: 40px;
}
.main-timeline2 {
  position: relative;
  padding-bottom: 10px;
}
.main-timeline2 .timeline {
  width: 90%;
  float: right;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
}
.main-timeline2 .timeline:before,
.main-timeline2 .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-title {
  width: 10%;
  float: left;
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.main-title .point {
  /*height:70px;*/
  overflow: hidden;
}
.main-title p {
  line-height: 25px;
  overflow: hidden;
}
.main-title p span {
  font-size: 14px;
}
.main-title p .img {
  margin-right: 8px;
  vertical-align: middle;
}
.main-title h3 {
  width: 36px;
  height: 123px;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(199, 199, 199, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 18px;
  font-size: 20px;
  color: rgba(0, 103, 177, 1);
  line-height: 30px;
  text-align: center;
  padding-top: 13px;
  margin-left: 25px;
}
.main-timeline2 .timeline:last-child,
.main-timeline2 .main-title:nth-last-child(2) {
  padding-bottom: 0;
}
.main-timeline2 .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline2 .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 45px;
}
.main-timeline2 .circle {
  width: 830px;
  float: right;
  position: relative;
  color: #000;
  text-align: left;
  overflow: hidden;
}
.main-timeline2 .circle_title,
.main-timeline2 .mod-list {
  background: rgb(239, 244, 254);
  border-radius: 8px;
  height: 60px;
  overflow: hidden;
}
.main-timeline2 .circle_title {
  line-height: 25px;
  padding: 8px 15px;
  margin-top: 15px;
}
.main-timeline2 .circle_title h4,
.main-timeline2 .circle_title p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}
.main-timeline2 .circle_title p span {
  padding-right: 10px;
}
.main-timeline2 .circle_title p .bd {
  padding-left: 10px;
}
.main-timeline2 .circle_title dl {
  overflow: hidden;
  margin-bottom: 0;
}
.main-timeline2 .circle_title dd,
.main-timeline2 .circle_title a,
.main-timeline2 .circle_title .bd {
  float: left;
  font-size: 12px;
  color: #474747;
}
.main-timeline2 .circle_title .bd {
  padding: 0 15px;
}
.main-timeline2 .circle_title dd:last-child > .bd {
  display: none;
}
.main-timeline2 .circle .mod-list {
  padding: 10px 15px;
  height: auto;
}
.main-timeline2 .circle .mod-list li {
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}
.main-timeline2 .mod-list li a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.main-timeline2 .mod-list li .mod-list-title2 {
  width: 680px;
}
.main-timeline2 .mod-list li .site {
  width: 80px;
  padding-left: 10px;
  color: #474747;
  float: left;
}
.main-timeline2 .site span {
  padding-right: 10px;
}
.main-timeline2 .content {
  display: table;
  padding: 0 5px;
  position: relative;
  width: auto;
  margin: 0;
}
.main-timeline2 .year {
  display: block;
  margin: 0 0 0 27px;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  width: 112px;
  height: 38px;
  background-color: #2a67b3;
  border-radius: 8px;
  margin-top: 2px;
  line-height: 38px;
}
.main-timeline2 .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 38px;
  left: -7px;
  display: inline-block;
}
.main-timeline2 .item {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.demo2 .item:nth-child(2n) .timeline-content:before {
  display: none;
}
.demo2 .item:nth-child(2n) .main-title {
  margin-top: 10px;
}
.main-timeline2 .bd_n:before {
  display: none;
}
.item_title .main-title {
  margin-top: 0;
}
.main-title p img {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
</style>